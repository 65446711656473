<template>
	<ul>
		<template v-for="(vo,idx) in listData">
			<li @click="FN.toPlayer(vo)">
				<div class="cont" :title="vo.name">
					<img v-lazy="formatUrl(vo?.surfacePlot || vo.info?.surfacePlot)" :key="vo.id" />
          <template v-if="vo.buyMode == 1">
            <span class="vip" v-if="vo.chargingMode === 2">VIP</span>
            <span class="pay" v-else-if="vo.chargingMode === 3">{{vo.gold}}金币</span>
            <!-- <span class="free" v-else>免费</span> -->
          </template>
					<span :class="{'date':true,'on':vo.date >= $store.state.dayStart}" v-if="$store.state.siteInfo.Preview">
						{{$Time.format("yy-mm-dd",vo.date)}}
					</span>
					<span class="time" v-if="vo.number > 1">{{vo.note}}</span>
          <span class="time" v-else>{{$Time.time(vo.duration)}}</span>
					<div class="tip">
						<i :style="'background-image: url('+$Svg('video.svg')+')'">{{vo.watchNums}}</i>
						<i :style="'background-image: url('+$Svg('hot1.svg')+')'">{{vo.popularity}}</i>
					</div>
					<div class="clear"></div>
				</div>
				<p :title="vo.name">{{vo.name}}</p>
				<!-- <div class="desc" v-if="vo.introduce">{{vo.introduce}}</div>
				<div class="desc" v-else>
					<template  v-for="(sub,idx) in vo.category">
						{{sub.name}}&nbsp;&nbsp;
					</template>
				</div> -->
				<!-- <div class="tip">
					<img :src="$Svg('video.svg')" />
					{{vo.watchNums}}

					<img :src="$Svg('renqi.svg')" />
					{{vo.popularity}}
				</div> -->
			</li>
		</template>
	</ul>
</template>
<script setup>
	import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import { useRouter } from "vue-router";
  import { formatUrl } from "@/utils/index";
	let router = useRouter();
	const props = defineProps({
		listData: { default: [] },
	})
	const FN = {
		toPlayer(item){
			let cate = item.category || []
			let cid = cate[cate.length-1].id || 0
			if(cid){
				router.push({name:'VodInfo',params:{cid:cid,id:item.id}})
			}
		}
	}
	onMounted(() => {
	})
</script>
<style lang='scss' scoped>
ul{
	display: grid;
	grid-template-columns: calc(25% - 8px) calc(25% - 7px) calc(25% - 7px) calc(25% - 8px);
	grid-gap: 10px;
	li{
		list-style-type: none;
		cursor: pointer;
		&:nth-last-child(1){ margin-bottom: 0; }
		&:nth-last-child(2){ margin-bottom: 0; }
		&:nth-last-child(3){ margin-bottom: 0; }
		&:nth-last-child(4){ margin-bottom: 0; }
		
		p{
			margin-top: 4px;
			margin-bottom: 8px;
			padding:0;
			font-size: 13px;
			color: #222222;
			display: block;
			// overflow: hidden;
			// white-space: nowrap;
			// text-overflow: ellipsis;
			display: -webkit-box;
			overflow: hidden;  
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
		}
		// .desc{
		// 	padding:2px 5px 0 5px;
		// 	font-size: 10px;
		// 	color: #999;
		// 	display: -webkit-box;
		// 	overflow: hidden;  
		// 	-webkit-line-clamp: 2;
		// 	-webkit-box-orient: vertical;
		// }
	}
}
</style>