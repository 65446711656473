
const Member = [
  {
    path: "index.html",
    name: "MemberIndex",
    meta: { title: "会员中心", menu: false, icon: "zhanghaoshezhi" },
    component: () => import("@/h5/menber/index.vue"),
  },
  {
    path: "login.html",
    name: "MemberLogin",
    meta: { title: "登录注册", menu: false },
    component: () => import("@/h5/menber/login.vue"),
  },
  {
    path: "home.html",
    name: "MemberHome",
    meta: { title: "个人中心", menu: false },
    component: () => import("@/h5/menber/home.vue"),
  },
  {
    path: "history.html",
    name: "MemberHistory",
    meta: { title: "观看历史", menu: false },
    component: () => import("@/h5/menber/other/history.vue"),
  },
  {
    path: "demand.html",
    name: "MemberDemand",
    meta: { title: "点播记录", menu: false },
    component: () => import("@/h5/menber/other/demandPlay.vue"),
  },
  {
    path: "gold.html",
    name: "MemberGold",
    meta: { title: "我的金币", menu: false },
    component: () => import("@/h5/menber/gold/index.vue"),
  },
  {
    path: "goldDeal.html",
    name: "MemberGoldDeal",
    meta: { title: "金币交易", menu: false },
    component: () => import("@/h5/menber/gold/goldDeal.vue"),
  },
  {
    path: "goldReap.html",
    name: "MemberGoldReap",
    meta: { title: "赚金币", menu: false },
    component: () => import("@/h5/menber/gold/goldReap.vue"),
  },
  {
    path: "goldRules.html",
    name: "MemberGoldRules",
    meta: { title: "签到规则", menu: false },
    component: () => import("@/h5/menber/gold/goldRules.vue"),
  },
  {
    path: "order.html",
    name: "MemberOrder",
    meta: { title: "我的订单", menu: false },
    component: () => import("@/h5/menber/order/index.vue"),
  },
  {
    path: "orderRecord.html",
    name: "MemberOrderRecord",
    meta: { title: "订单记录", menu: false },
    component: () => import("@/h5/menber/order/orderRecord.vue"),
  },
  {
    path: "orderDel.html",
    name: "MemberOrderDel",
    meta: { title: "订单反馈与删除", menu: false },
    component: () => import("@/h5/menber/order/orderDel.vue"),
  },
  {
    path: "news.html",
    name: "MemberNews",
    meta: { title: "我的消息", menu: false },
    component: () => import("@/h5/menber/other/news.vue"),
  },
  {
    path: "account.html",
    name: "MemberAccount",
    meta: { title: "账号设置", menu: false },
    component: () => import("@/h5/menber/account/index.vue"),
  },
  {
    path: "accountEdit.html",
    name: "MemberAccountEdit",
    meta: { title: "账号修改", menu: false },
    component: () => import("@/h5/menber/account/update.vue"),
  },
  {
    path: "address.html",
    name: "MemberAccountAddress",
    meta: { title: "提现地址", menu: false },
    component: () => import("@/h5/menber/account/addr.vue"),
  },
  {
    path: "addrAdd.html",
    name: "MemberAccountAddressAdd",
    meta: { title: "添加提现地址", menu: false },
    component: () => import("@/h5/menber/account/addrAdd.vue"),
  },
  {
    path: "pay.html",
    name: "MemberPay",
    meta: { title: "支付", menu: false },
    component: () => import("@/h5/menber/pay/pay.vue"),
  },
  {
    path: "payStatus.html",
    name: "MemberPayStatus",
    meta: { title: "支付状态", menu: false },
    component: () => import("@/h5/menber/pay/status.vue"),
  },
]


export default [
  {
    path: "/",
    name: "APP",
    meta: { title: "应用", menu: true },
    component: () => import("@/h5/layout.vue"),
    redirect: { name: "Index" },
    children: [
      {
        path: "index.html",
        name: "Index",
        meta: { title: "精选", menu: true },
        component: () => import("@/h5/index/layout.vue"),
      },
      {
        path: "topic.html",
        name: "Topic",
        meta: { title: "专题", menu: true },
        component: () => import("@/h5/topic/index.vue"),
      },
      {
        path: "topic/info.html",
        name: "TopicInfo",
        meta: { title: "专题详细", menu: false },
        component: () => import("@/h5/topic/info.vue"),
      },
      {
        path: "people.html",
        name: "People",
        meta: { title: "明星", menu: true },
        component: () => import("@/h5/star/people.vue"),
      },
      {
        path: "star.html",
        name: "Star",
        meta: { title: "明星", menu: false },
        component: () => import("@/h5/star/star.vue"),
      },
      {
        path: "/star/:id.html",
        name: "StarInfo",
        meta: { title: "明星详细", menu: false },
        component: () => import("@/h5/star/info.vue"),
      },
      {
        path: "/cate/:cid.html",
        name: "CateList",
        meta: { title: "类型", menu: false },
        component: () => import("@/h5/index/layout.vue"),
      },
      {
        path: "/cate/:cid/:id.html",
        name: "VodInfo",
        meta: { title: "视频详细", menu: false },
        component: () => import("@/h5/vod/info.vue"),
      },
      {
        path: "search.html",
        name: "Search",
        meta: { title: "搜索", menu: false },
        component: () => import("@/h5/search.vue"),
      },
      {
        path: "member",
        name: "Member",
        meta: { title: "会员中心", menu: false },
        component: () => import("@/h5/menber/layout.vue"),
        redirect: { name: "MemberLogin" },
        children: Member,
      },
      {
        path: "discovery.html",
        name: "Discovery",
        meta: { title: "发现", menu: false },
        component: () => import("@/h5/discovery/index.vue"),
      },
      {
        path: "agent",
        name: "Agent",
        meta: { title: "我是代理", menu: false },
        component: () => import("@/h5/menber/agent/layout.vue"),
        redirect: { name: "AgentIndex" },
        children: [
          {
            path: "index.html",
            name: "AgentIndex",
            meta: { title: "代理中心", menu: false, icon: "zhanghaoshezhi" },
            component: () => import("@/h5/menber/agent/index.vue"),
          },
          {
            path: "output.html",
            name: "AgentOutput",
            meta: { title: "提现记录", menu: true, icon: "menu1.svg" },
            component: () => import("@/h5/menber/agent/make_record.vue"),
          },
          {
            path: "get.html",
            name: "AgentGet",
            meta: { title: "分佣记录", menu: true, icon: "menu2.svg" },
            component: () => import("@/h5/menber/agent/commission_record.vue"),
          },
          {
            path: "team.html",
            name: "AgentTeam",
            meta: { title: "我的团队", menu: true, icon: "menu3.svg" },
            component: () => import("@/h5/menber/agent/team.vue"),
          },
          {
            path: "rule.html",
            name: "AgentRule",
            meta: { title: "提成规则", menu: true, icon: "menu4.svg" },
            component: () => import("@/h5/menber/agent/commission_rule.vue"),
          },
          {
            path: "make.html",
            name: "AgentMake",
            meta: { title: "提现", menu: false, icon: "zhanghaoshezhi" },
            component: () => import("@/h5/menber/agent/make.vue"),
          },
          {
            path: "scancode.html",
            name: "AgentScanCode",
            meta: { title: "扫码", menu: false, icon: "zhanghaoshezhi" },
            component: () => import("@/h5/menber/agent/scan.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/download.html",
    name: "Download",
    meta: { title: "下载", menu: false },
    component: () => import("@/h5/download.vue"),
  },
  {
    path: "/customer",
    name: "Customer",
    meta: { title: "客服", menu: false },
    component: () => import("@/components/customer.vue"),
  },
  {
    path: "/customer2",
    name: "Customer2",
    meta: { title: "聊天记录", menu: false },
    component: () => import("@/components/customer2.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: "404", menu: false },
    component: () => import("@/h5/404.vue"),
  },
];