/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, _args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, _args);
        if (!timeout) context = _args = null;
      }
    }
  };
  return function (...args) {
    context = this;
    _args = args;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
/**
 * @param {Function} fu
 * @param {number} delay
 * @return {*}
 */
export function throttle(fn, delay) {
  var ctx;
  var args;
  // 记录上次触发事件
  var previous = Date.now();

  var later = function () {
    fn.apply(ctx, args);
  };

  return function () {
    ctx = this;
    args = arguments;
    var now = Date.now();
    // 本次事件触发与上一次的时间比较
    var diff = now - previous - delay;

    // 如果隔间时间超过设定时间，即再次设置事件触发的定时器
    if (diff >= 0) {
      // 更新最近事件触发的时间
      previous = now;
      setTimeout(later, delay);
    }
  };
}

// 7.是否boolean
export const isBoolean = (o) => {
  return Object.prototype.toString.call(o).slice(8, -1) === "Boolean";
};
// 11.是否对象
export const isObj = (o) => {
  return Object.prototype.toString.call(o).slice(8, -1) === "Object";
};
// 12.是否数组
export const isArray = (o) => {
  return Object.prototype.toString.call(o).slice(8, -1) === "Array";
};
/**
 * @description: 封装sessionStorage和localStorage
 * @param {*}
 * @return {*}
 */
export const storage = {
  setLocal: (key, value) => {
    if (isArray(value) || isObj(value) || isBoolean(value)) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
  },
  getLocal: (key) => {
    let value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return value;
    }
  },
  clearOneLocal: (key) => {
    window.localStorage.removeItem(key);
  },
  clearAllLocal: () => {
    // indexDBStore 不清除
    const indexDBStore = storage.getLocal("indexDBStore");
    window.localStorage.clear();
    storage.setLocal("indexDBStore", JSON.stringify(indexDBStore));
  },
  setSession: (key, value) => {
    //正常设置，会覆盖原值
    if (isArray(value) || isObj(value) || isBoolean(value)) {
      window.sessionStorage.setItem(
        key,
        window.btoa(window.encodeURIComponent(JSON.stringify(value)))
      );
    } else {
      window.sessionStorage.setItem(
        key,
        window.btoa(window.encodeURIComponent(value))
      );
    }
    // window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  appendSession: (key, value) => {
    //追加赋值，不会覆盖原值
    let getValue = window.sessionStorage.getItem(key);
    if (getValue) {
      let oldValue = JSON.parse(getValue);
      let newValue = Object.assign(oldValue, value);
      window.sessionStorage.setItem(key, JSON.stringify(newValue));
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  },
  getSession: (key) => {
    //需要判断取值格式，如果是string或者undefined，不能JSON.PARSE()
    let value = window.sessionStorage.getItem(key);
    if (!value) {
      return value;
    } else {
      try {
        if (
          isArray(JSON.parse(window.decodeURIComponent(window.atob(value)))) ||
          isObj(JSON.parse(window.decodeURIComponent(window.atob(value))))
        ) {
          return JSON.parse(window.decodeURIComponent(window.atob(value)));
        } else {
          if (window.decodeURIComponent(window.atob(value)) == "true") {
            return true;
          } else if (window.decodeURIComponent(window.atob(value)) == "false") {
            return false;
          } else {
            return window.decodeURIComponent(window.atob(value));
          }
        }
      } catch (e) {
        return window.decodeURIComponent(window.atob(value));
      }
    }
  },
  clearOneSession: (key) => {
    window.sessionStorage.removeItem(key);
  },
  clearAllSession: () => {
    window.sessionStorage.clear();
  },
};
export function parseTime(time, cFormat = "{y}-{m}-{d} {h}:{i}:{s}") {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else {
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }
    if (typeof time === "number" && time.toString().length <= 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

//时间格式化 秒数转化为时分秒 自动补全0
export function formatSeconds(value) {
  const by = (val) => {
    if (val < 10 && val > 0) return "0" + val;
    if (val == 0) return "00";
    return `${val}`;
  };
  let second = parseInt(value);
  let minute = 0;
  let hour = 0;
  if (second > 60) {
    minute = parseInt(`${second / 60}`);
    second = second % 60;
    if (minute > 60) {
      hour = parseInt(`${minute / 60}`);
      minute = minute % 60;
    }
  }
  let result = "" + by(second);
  if (minute > 0) {
    result = "" + by(minute) + ":" + result;
  } else {
    result = "00" + ":" + result;
  }
  if (hour > 0) result = "" + by(hour) + ":" + result;
  return result;
}
//时间格式，几天前之类的
export function getTimeFormatText(date) {
  let minute = 60; // 1分钟
  let hour = 60 * minute; // 1小时
  let day = 24 * hour; // 1天
  let month = 31 * day; // 月
  let year = 12 * month; // 年
  // 将-替换成/，因为下面这个构造函数只支持/分隔的日期字符串
  if (date == null) {
    return null;
  }
  // date = date * 1000
  // date = date.replace(/-/g,'/')
  // let diff = new Date().getTime() - new Date(date).getTime();
  let diff = Math.ceil(new Date().getTime() / 1000) - date;
  //
  let r = 0;
  if (diff > year) {
    r = diff / year;
    return Math.round(r) + "年前";
  }
  if (diff > month) {
    r = diff / month;
    return Math.round(r) + "个月前";
  }
  if (diff > day) {
    r = diff / day;
    return Math.round(r) + "天前";
  }
  if (diff > hour) {
    r = diff / hour;
    return Math.round(r) + "个小时前";
  }
  if (diff > minute) {
    r = diff / minute;
    return Math.round(r) + "分钟前";
  }
  return Math.ceil(diff / minute) + "秒钟前";
}

export function isHasImg(pathImg) {
  // var ImgObj: any = new Image();
  // ImgObj.src = pathImg;
  // return ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)
  if (!pathImg) return false;
  try {
    var xmlHttp = null;
    if (window.XMLHttpRequest) {
      xmlHttp = new XMLHttpRequest();
    }
    xmlHttp.open("Get", pathImg, false);
    xmlHttp.send();
    if (xmlHttp.status == 200) return true;
    else return false;
  } catch (error) {
    return false
  }
}
/**
 * @param {Number}
 * @returns {String}
 * 获取当前时间前后某一天
 */
export function getDay(day) {
  //处理时间函数
  const doHandleMonth = (month) => {
    var m = month;
    if (month.toString().length == 1) {
      m = "0" + month;
    }
    return m;
  };
  var today = new Date();
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}
//验证密码
export const isPass = (s) => {
  const PASS_REG = /^[a-zA-Z0-9_]{6,20}$/;
  //密码由6-20个字符，字母、数字、下划线组成!
  // const PASS_REG = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6, 20}$/
  // const PASS_REG =
  //     /((^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8, 32}$)|(^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8, 32}$)|(^(?=.*\d)(?=.*[a-z])(?=.*\W)[\da-zA-Z\W]{8, 32}$)|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z\W]{8, 32}$))/;
  return PASS_REG.test(s);
};

/**
 * @description: 复制
 * @param String
 */

// export function copyUrl(url: string, isNotice = true, msg = '复制成功') {
//   // var url = JSON.stringify(t)
//   //
//   if (url) {
//     var input = document.createElement('input');
//     document.body.appendChild(input);
//     input.setAttribute('value', url);
//     input.select();
//     document.execCommand("copy"); // 执行浏览器复制命令
//     if (document.execCommand('copy')) {
//       document.execCommand('copy');
//     }
//     if (isNotice) {
//       message.success(msg)
//     }
//     document.body.removeChild(input);
//   } else {
//     message.warning('不能复制空')
//   }
// }
export function getQueryVariable() {
  let href = window.location.href;
  let query = href.substring(href.indexOf("?") + 1);
  let vars = query.split("&");
  let obj = {};
  for (var i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    obj[pair[0]] = pair[1];
  }
  return obj;
}
// 替换邮箱字符
export function regEmail(email) {
  let new_email = "";
  if (String(email).indexOf("@") > 0) {
    let str = email.split("@"),
      _s = "";
    if (str[0].length > 3) {
      for (let i = 0; i < str[0].length - 3; i++) {
        _s += "*";
      }
    }
    new_email = str[0].substr(0, 3) + _s + "@" + str[1];
  }
  return new_email;
}

export function isMobile() {
  var regex_match =
    /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
  var u = navigator.userAgent;
  if (null == u) {
    return true;
  }
  var result = regex_match.exec(u);
  if (null == result) {
    return false;
  } else {
    return true;
  }
}
export function isIos() {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}
export function isAndroid() {
  var u = navigator.userAgent;
  return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
}
export function getImageUrl(name) {
  return new URL(`/src/assets/image/${name}.png`, import.meta.url).href;
}
/*
 * 深度递归搜索
 * @param {Array} arr 你要搜索的数组
 * @param {Function} condition 回调函数，必须返回谓词，判断是否找到了。会传入(item, index, level)三个参数
 * @param {String} children 子数组的key
 */
export const deepFind = (arr, condition, children) => {
  let main = [];
  try {
    (function poll(arr, level) {
      if (!Array.isArray(arr)) return;
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        main[level] = item;
        const isFind = (condition && condition(item, i, level)) || false;
        if (isFind) {
          throw Error;
        } else if (children && item[children] && item[children].length) {
          poll(item[children], level + 1);
        } else if (i === arr.length - 1) {
          main.length = main.length - 1;
        }
      }
    })(arr, 0);
  } catch (err) { }
  return main;
};

/*
 * 播放器广告
 * @param 播放前广告、播放暂停广告
 */
export function insertPlayerAds(playerSuspend, playerBefore) {
  const createNode = (base, val, close) => {
    let closeNode = `
      <div onclick="closeTplAds(this)" style="position:absolute; top:0px; right:0px;
      margin:1px;width:15px; height:15px;line-height:16px; 
      background:#000;font-size:11px;text-align:center;">
        <a href="javascript:;" style="color:white;text-decoration:none;">
          <svg t="1682240810163" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9189" width="14" height="14">
            <path d="M601.376 512l191.52-191.52c28.096-28.096 30.976-71.168 6.4-95.744s-67.68-21.696-95.744 6.4l-191.52 191.52-191.52-191.52c-28.096-28.096-71.168-30.976-95.744-6.368s-21.696 67.68 6.4 95.744l191.52 191.52-191.52 191.52c-28.096 28.096-30.976 71.168-6.368 95.744s67.68 21.696 95.744-6.4l191.52-191.52 191.52 191.52c28.096 28.096 71.168 30.976 95.744 6.4s21.696-67.68-6.4-95.744l-191.52-191.52z" fill="#fff" p-id="9190">
            </path>
          </svg>
        </a>
      </div>`
    let sty = "display:none;"
    const formatUrl = (url, api) => {
      return url?.includes('http') ? url : api + url
    }
    return `<div style="height: 100%;${ close ? sty : "" }">
      <a href="${ val?.link }" target="_blank">
        <img src="${ formatUrl(val?.content, base) }" style="width: 100%; height: 100%;object-fit: cover;">
      </a>
      ${ close ? closeNode : '' }
    </div>`
  }
  let baseApi = storage.getSession("tpl01Api") || ""
  //暂停广告
  if (playerSuspend?.length > 0) {
    let randomStop = playerSuspend.length > 1 ? Math.round(Math.random() * playerSuspend.length - 1) : 0
    let stopAds = playerSuspend[randomStop]
    window.tplPsAds = createNode(baseApi, stopAds, true)
  }
  //播放前广告
  if (playerBefore?.length > 0) {
    let randomBefore = playerBefore.length > 1 ? Math.round(Math.random() * playerBefore.length - 1) : 0
    let beforeAds = playerBefore[randomBefore]
    window.tplPbeAds = createNode(baseApi, beforeAds, false)
  }
  // console.log('页面接受参数：', playerSuspend)
}

/*
 * 图片路径
 * @param 图片地址
 */
export function formatUrl(url) {
  let baseApi = storage.getSession("tpl01Api") || ""
  return url?.includes('http') ? url : baseApi + url
}


//引入slogan广告
export function insertSlogan(val){
  const styleNode = document.createElement("div");
  styleNode.id = 'sloganDom'
  styleNode.style.position = 'fixed'
  styleNode.style.inset = '0'
  styleNode.style.zIndex = '99999'
  styleNode.style.background = '#fff'
  try {
    styleNode.innerHTML = `<div style="width:100vw;height:100vh;">
      <a href="${ val?.link }" target="_blank">
        <img src="${ formatUrl(val?.content) }" style="width: 100%; height: 100%;object-fit: contain;">
      </a>
    </div>`;
    let bodyNode = document.querySelector("body");
    bodyNode.appendChild(styleNode);
  } catch (error) {
    console.log('引入slogan广告==')
    console.error(error);
  }
};
//引入引导页广告
export function insertGuide(map){
  const styleNode = document.createElement("div");
  styleNode.id = 'guideDom'
  styleNode.style.position = 'fixed'
  styleNode.style.inset = '0'
  styleNode.style.zIndex = '99999'
  styleNode.style.background = '#fff'

  window.closeTplGuide = function (that) {
    let parent = that.parentNode;
    parent.style.display = 'none'
    let body = document.querySelector("body");
    body.className = ''
  }
  try {
    let str = `<div class="swiper-guide"><div class="swiper-wrapper">`
    map.forEach(v => {
      str += `
      <div class="swiper-slide">
        <a target="${v.target}" href="${v.link}">
          <img style="height:100vh;width:100vw;" src="${formatUrl(v.content)}" />
        </a>
      </div>`
    })
    str += `</div></div>`
    let closeNode = `<div onclick="closeTplGuide(this)" 
        style="position:absolute; top:2px; right:2px;
  width:42px; height:20px;line-height:20px;
  z-index:9999;color: #fff;background:#000;
  font-size:11px;text-align:center;">跳过
        </div>`
    str += closeNode
    // console.log(str, '====')
    styleNode.innerHTML = str;
    setTimeout(() => {
      new Swiper(".swiper-guide", {
        spaceBetween: 0,
        autoplay: {
          delay: 2000,
        },
        loop: false,
        slidesPerView: 1,
      });
    }, 500);
    let bodyNode = document.querySelector("body");
    bodyNode.className = 'el-popup-parent--hidden'
    bodyNode.appendChild(styleNode);
  } catch (error) {
    console.error(error);
  }
};