import router from "./router";
import store from "./store";
import { getToken, isLoginAuth } from "@/utils/auth";
const whiteList = [
  "Member",
  "MemberGold",
  "MemberOrder",
  "MemberHistory",
  "MemberRecord",
  "MemberMessage",
  "MemberAccount",
  "MemberIndex",
  "MemberHome",
  "MemberHistory",
  "MemberDemand",
  "MemberGold",
  "MemberGoldDeal",
  "MemberGoldReap",
  "MemberGoldRules",
  "MemberOrder",
  "MemberOrderRecord",
  "MemberOrderDel",
  "MemberNews",
  "MemberAccount",
  "MemberAccountEdit",
  "MemberAccountAddress",
  "MemberAccountAddressAdd",
  "MemberPay",

  "AgentIndex",
  "AgentOutput",
  "AgentGet",
  "AgentTeam",
  "AgentRule",
  "AgentMake",
  "AgentScanCode",
];
router.beforeEach((to, from, next) => {
  //
  document.title = to.meta.title;
  const hasToken = getToken();
  //
  if (hasToken) {
    next();
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      // store.dispatch('logout')
      next({ path: "/" });
    } else {
      next();
    }
  }
});

// router.afterEach(() => {
//
// })
