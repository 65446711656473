export default [{
  path: "/",
  name: "APP",
  meta: { title: "应用", menu: true },
  component: () => import("@/web/layout.vue"),
  redirect: { name: "Index" },
  children: [{
    path: "index.html",
    name: "Index",
    meta: { title: "精选", menu: true },
    component: () => import("@/web/index/layout.vue"),
  }, {
    path: "topic.html",
    name: "Topic",
    meta: { title: "专题", menu: true },
    component: () => import("@/web/topic/index.vue"),
  }, {
    path: "topic/info.html",
    name: "TopicInfo",
    meta: { title: "专题视频", menu: false },
    component: () => import("@/web/topic/info.vue"),
  }, {
    path: "people.html",
    name: "People",
    meta: { title: "明星", menu: true },
    component: () => import("@/web/people/layout.vue"),
  }, {
    path: "star.html",
    name: "Star",
    meta: { title: "明星", menu: false },
    component: () => import("@/web/people/more.vue"),
  }, {
    path: "star/:id.html",
    name: "StarInfo",
    meta: { title: "明星详细", menu: false },
    component: () => import("@/web/people/info.vue"),
  }, {
    path: "/cate/:cid.html",
    name: "CateList",
    meta: { title: "类型", menu: false },
    component: () => import("@/web/cate.vue"),
  }, {
    path: "/cate/:cid/:id.html",
    name: "VodInfo",
    meta: { title: "视频详细", menu: false },
    component: () => import("@/web/vodinfo.vue"),
  }, {
    path: "search.html",
    name: "Search",
    meta: { title: "搜索", menu: false },
    component: () => import("@/web/search.vue"),
  }, {
    path: "member",
    name: "Member",
    meta: { title: "会员中心", menu: false },
    component: () => import("@/web/menber/index.vue"),
    redirect: { name: "MemberGold" },
    children: [{
      path: "gold.html",
      name: "MemberGold",
      meta: { title: "我的金币", menu: true, icon: "wodejinbi" },
      component: () => import("@/web/menber/gold/layout.vue"),
    }, {
      path: "order.html",
      name: "MemberOrder",
      meta: { title: "我的订单", menu: true, icon: "wodedingdan" },
      component: () => import("@/web/menber/vip/index.vue"),
    }, {
      path: "history.html",
      name: "MemberHistory",
      meta: { title: "观看历史", menu: true, icon: "guankanlishi" },
      component: () => import("@/web/menber/history/index.vue"),
    }, {
      path: "record.html",
      name: "MemberRecord",
      meta: { title: "点播记录", menu: true, icon: "dianbo" },
      component: () => import("@/web/menber/demandPlay/index.vue"),
    }, {
      path: "message.html",
      name: "MemberMessage",
      meta: { title: "我的消息", menu: true, icon: "wodexiaoxi1" },
      component: () => import("@/web/menber/news/index.vue"),
    }, {
      path: "account.html",
      name: "MemberAccount",
      meta: { title: "账号设置", menu: true, icon: "zhanghaoshezhi" },
      component: () => import("@/web/menber/account/index.vue"),
    }]
  }, {
    path: "agent",
    name: "Agent",
    meta: { title: "我是代理", menu: false },
    component: () => import("@/web/menber/index.vue"),
    redirect: { name: "AgentIndex" },
    children: [{
      path: "index.html",
      name: "AgentIndex",
      meta: { title: "我的主页", menu: true, icon: "agmenu1.svg" },
      component: () => import("@/web/menber/agent/index.vue"),
    }, {
      path: "output.html",
      name: "AgentOutput",
      meta: { title: "提现记录", menu: true, icon: "agmenu2.svg" },
      component: () => import("@/web/menber/agent/make_record.vue"),
    }, {
      path: "get.html",
      name: "AgentGet",
      meta: { title: "分佣记录", menu: true, icon: "agmenu3.svg" },
      component: () => import("@/web/menber/agent/commission_record.vue"),
    }, {
      path: "team.html",
      name: "AgentTeam",
      meta: { title: "我的团队", menu: true, icon: "agmenu4.svg" },
      component: () => import("@/web/menber/agent/team.vue"),
    }, {
      path: "rule.html",
      name: "AgentRule",
      meta: { title: "提成规则", menu: true, icon: "agmenu5.svg" },
      component: () => import("@/web/menber/agent/commission_rule.vue"),
    }, {
      path: "make.html",
      name: "AgentMake",
      meta: { title: "提现", menu: false, icon: "zhanghaoshezhi" },
      component: () => import("@/web/menber/agent/make.vue"),
    }, {
      path: "/:pathMatch(.*)*",
      name: "Agent404",
      meta: { title: "404", menu: false },
      component: () => import("@/web/404.vue"),
    }]
  }]
},
{
  path: "/customer",
  name: "Customer",
  meta: { title: "客服", menu: false },
  component: () => import("@/components/customer.vue"),
},
{
  path: "/customer2",
  name: "Customer2",
  meta: { title: "聊天记录", menu: false },
  component: () => import("@/components/customer2.vue"),
},
 {
  path: "/:pathMatch(.*)*",
  name: "404",
  meta: { title: "404", menu: false },
  component: () => import("@/web/404.vue"),
}];