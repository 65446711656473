import { createStore } from "vuex";
import { storage, isMobile, insertPlayerAds, insertSlogan, insertGuide } from "@/utils/index";
import { removeToken, setToken } from "@/utils/auth";
import app from "@/main.js";

export default createStore({
  state: {
    second: 0,
    milliseconds: 0,
    dayStart:
      new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() /
      1000,
    loading: false,
    pageKey: 0,
    siteInfo: {},
    num: storage.getSession("numMap") ?? {},
    sitePub: {},
    cateList: {},
    special: [],
    //新增
    userInfo: storage.getSession("tpl01UserInfo") || {},
    isLogin: false,
    loginToPath: "",
    isPay: false,
    //获取当前路由
    ispaying: false,
    payType: storage.getSession("tpl01PayType") || "gold",
    payIndex: 0,
    api: storage.getSession("tpl01Api") || "",
    watchInfo: {},
    h5PayReturn: "/member/pay.html",
    siteOtherInfo: storage.getSession("tpl01SiteInfo") || {},
    h5LoginReturnUrl: "",
    rechargePrice: 0,
    isWs: true,
    siteName:'',
    isAgentModel: false,
    confirmModel: false, //二次确认
    commentConfig: {// 评论配置
      needLogin: false, // 是否需要登录
      open: false, // 是否开启评论
    },
    iosUrl: '',
    apkUrl:  '',
    isAgentOpen: false
  },
  getters: {
    userInfo: (state) => state.userInfo,
    isLogin: (state) => state.isLogin,
    isPay: (state) => state.isPay,
    isPayIng: (state) => state.ispaying,
    payType: (state) => state.payType,
    api: (state) => state.api,
    watchInfo: (state) => state.watchInfo,
    siteOtherInfo: (state) => state.siteOtherInfo,
    rechargePrice: (state) => state.rechargePrice,
    isWs: (state) => state.isWs,
    isAgentModel: (state) => state.isAgentModel,
    CommentConfig: (state) => state.commentConfig,
    confirmModel: state => state.confirmModel
  },
  mutations: {
    SETIOSAPK(state, val) {
      state.iosUrl = val;
    },
    SETAPK(state, val) {
      state.apkUrl = val;
    },
    SETCOMMENTCONFIG(state, val) {
      state.commentConfig = val;
    },
    SETCONFIRMMODEL(state, val) {
      state.confirmModel = val;
    },
    SETAGENTMODEL(state, val) {
      state.isAgentModel = val;
    },
    SETWS(state, val) {
      state.isWs = val;
    },
    SETSITEINFO(state, val) {
      state.siteOtherInfo = val;
      storage.setSession("tpl01SiteInfo", val);
    },
    SETH5RETURNURL(state, val) {
      state.h5LoginReturnUrl = val;
    },
    SETH5PAYURL(state, val) {
      state.h5PayReturn = val;
    },
    SETRECHAR(state, val) {
      state.rechargePrice = val;
    },
    SETISPAYING(state, val) {
      state.ispaying = val;
    },
    SETWATCHINFO(state, val) {
      state.watchInfo = val;
    },
    SETAPI(state, val) {
      storage.setSession("tpl01Api", val);
      state.api = val;
    },
    SETPAYINDEX(state, val) {
      state.payIndex = val;
    },
    SETPAYTYPE(state, val) {
      storage.setSession("tpl01PayType", val);
      state.payType = val;
    },
    SETISPAY(state, val) {
      state.isPay = val;
    },
    SETLOGINPATH(state, val) {
      state.loginToPath = val;
    },
    SETISLOGIN(state, val) {
      state.isLogin = val;
    },
    SETUSERINFO(state, val) {
      state.userInfo = val;
      storage.setSession("tpl01UserInfo", val);
    },
    SETISITENAME(state, val) {
      state.siteName = val;
    },
    //以前
    Loading(state, val) {
      state.loading = val || false;
    },
    SiteInfo(state, info) {
      state.siteInfo = info || {};
    },
    SiteNums(state, num) {
      state.num = num || {};
      storage.setSession("numMap", num);
    },
    CateData(state, list) {
      state.cateList = list || [];
    },
    SiteSpecial(state, list) {
      state.special = list || [];
    },
    SitePub(state, info) {
      state.sitePub = info || {};
    },
    PageKey(state, sec) {
      state.pageKey = Math.random() * 1000;
    },
    SECOND(state, sec) {
      state.second = sec || 0;
    },
    MILLISECONDS(state, sec) {
      state.milliseconds = sec || 0;
    },
  },
  actions: {
    insertSiteInfo({ commit, state }, ajax) {
      return ajax.get(`/template/site_info`).then(({ data }) => {
        data = data.info;
        commit("SETSITEINFO", data);
        if (data.icon) insertFavicon(state.api + data.icon);
        // if (data.statisticalCode) insertJs(`${data.statisticalCode}`);
      });
    },
    refreshInfo({ commit, state, dispatch }) {
      return app.config.globalProperties.$http
        .get(`/member/info`)
        .then((res) => {
          commit("SETUSERINFO", res.data);
        });
    },
    logout({ commit, state, dispatch }) {
      return new Promise((resolve) => {
        try {
          if(window.serCtr?.socket) window.serCtr.close()
        } catch (e) { }
        removeToken();
        // storage.clearAllSession()
        window.sessionStorage.removeItem("shareId");
        window.sessionStorage.removeItem("tpl01UserInfo");
        state.userInfo = {};
        storage.setSession("isTourists", false);
        // dispatch('createTourists', app.config.globalProperties)
        resolve();
      });
    },
    // 创建游客
    // createTourists({ commit, state }, ajax) {
    //   let ajaxs = ajax || app.config.globalProperties.$http;
    //   if (!ajaxs) return false;
    //   let isTourists = storage.getSession("isTourists");
    //   if (isTourists) {
    //     return false;
    //   }

    //   if (state.siteOtherInfo.visitor != 1) return false;
    //   ajaxs
    //     .post("/member/tourists/register")
    //     .then(({ data }) => {
    //       storage.setSession("isTourists", true);
    //       setToken(data.token);
    //       commit("SETUSERINFO", data.userInfo);
    //     })
    //     .catch((err) => {
    //       return {};
    //     });
    // },
    // 站点信息
    getSiteInfo(context, ajax) {
      return ajax
        .get("/template/site_info")
        .then((res) => {
          let data = res.data || {};
          context.commit("SETCOMMENTCONFIG", data.commentConfig);
          context.commit("SiteInfo", data);
          context.commit("SiteNums", data.nums || {});
          //综合部分
          context.commit("SETSITEINFO", data.info);
          if (data.info.icon) insertFavicon(context.state.api + data.info.icon);
          // if (data.info.statisticalCode) insertJs(`${data.info.statisticalCode}`);
          context.dispatch('getApk', ajax)
          ajax.get(`/member/agent/open`)
            .then((res2) => {
              context.state.isAgentOpen = res2.data;
            });
          return res.data || {};
        })
        .catch((err) => {
          console.dir(err);
          return {};
        });
    },
    getApk({ commit }, ajax){
        
        // apk
        ajax.get(`/app/apk/download`).then(({ data }) => {
            commit('SETAPK', data)
        }).catch(() => {})

        // ios
        ajax.get(`/app/ios/download`).then(({ data }) => {
            let d = data || {}
            if(d.url)d.url = location.origin + d.url
            commit('SETIOSAPK', data)
        }).catch(() => {})
    },
    // 影片分类
    getSiteClass(context, ajax) {
      return ajax
        .get("/template/vod/category")
        .then((res) => {
          context.commit("CateData", res.data || []);
          return res.data || {};
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    // 影片专题
    getSiteTheme(context, ajax) {
      return ajax
        .get("/template/special")
        .then((res) => {
          context.commit("SiteSpecial", res.data || []);
          return res.data || {};
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    // 站点广告
    getSitePub(context, ajax) {
      return ajax
        .get("/template/site_ads")
        .then(({ data }) => {
          //注入播放器广告
          insertPlayerAds(data.playerSuspend, data.playerBefore)
          // if(data.slogan?.length > 0){
          //   insertSlogan(data.slogan[0])
          // }
          // debugger
          // let map = data.list.filter(v => v.position == 'boot')
          // insertGuide(map)
          context.commit("SitePub", data);
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
});

//引入统计代码
const insertJs = (text) => {
  const styleNode = document.createElement("script");
  try {
    styleNode.innerHTML = text;
    let headNode = document.querySelector("head");
    headNode.appendChild(styleNode);
  } catch (error) {
    console.log('引入统计代码==')
    console.error(error);
  }
};
//注入网站图标
const insertFavicon = (link) => {
  let favicon = document.querySelector('link[rel="icon"]');
  if (favicon !== null) {
    favicon.href = link;
  } else {
    favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.href = link;
    document.head.appendChild(favicon);
  }
};
