<template>
  <el-dialog v-model="agent.visible" destroy-on-close append-to-body :close-on-click-modal="false"
    :top="type == 'pc' ? '15%' : '40%'" class="agentModel" :class="{ 'agentPcModel': type == 'pc' }" :zIndex="10010"
    :width="type == 'pc' ? 520 : 312" :show-close="false">
    <template v-if="type == 'h5'">
      <img class="modelbg" src="@/assets/image/agentmodel.png">
      <div class="click-box">
        <p>请输入邀请码</p>
        <input type="text" v-model="invitation" placeholder="邀请码">
        <button @click="agent.confirm()" :disabled="!invitation">立即提交</button>
        <div class="answer">想要成为一级代理？<span @click="agent.put()">点击前往</span></div>
      </div>
    </template>
    <template v-if="type == 'pc'">
      <img class="modelbg" src="@/assets/image/agentmodelPc.png">
      <div class="click-box">
        <p>请输入邀请码</p>
        <input type="text" v-model="invitation" @input="setinput(invitation)" placeholder="邀请码">
        <button @click="agent.confirm()" :disabled="!invitation">立即提交</button>
        <div class="answer">想要成为一级代理？<span @click="agent.put()">点击前往</span></div>
      </div>
    </template>
    <template #footer>
      <div class="close" @click="agent.close()">
        <svg t="1682240810163" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="9189" width="20" height="20">
          <path fill="#3D3D3D"
            d="M601.376 512l191.52-191.52c28.096-28.096 30.976-71.168 6.4-95.744s-67.68-21.696-95.744 6.4l-191.52 191.52-191.52-191.52c-28.096-28.096-71.168-30.976-95.744-6.368s-21.696 67.68 6.4 95.744l191.52 191.52-191.52 191.52c-28.096 28.096-30.976 71.168-6.368 95.744s67.68 21.696 95.744-6.4l191.52-191.52 191.52 191.52c28.096 28.096 71.168 30.976 95.744 6.4s21.696-67.68-6.4-95.744l-191.52-191.52z"
            p-id="9190">
          </path>
        </svg>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, toRefs, onMounted, getCurrentInstance, watch } from 'vue'
import router from "@/router";
import { ElMessage as message } from 'element-plus';
import { useStore } from "vuex";
import { storage } from "@/utils/index";

const props = defineProps({
  type: {
    type: String,
    default: 'pc'
  }
})

const setinput = (e) => {
  agent.invitation = e.replace(/[\W]/g, '')
};

const store = useStore();
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

watch(
  () => store.getters.isAgentModel,
  (val) => {
    agent.visible = val
  }
)
const emits = defineEmits(['ok'])

const agent = reactive({
  visible: false,
  invitation: '', //8cvJHgpW
  confirm() {
    storage.setSession('inviteCodeTpl01', agent.invitation.trim())
    store.commit('SETCONFIRMMODEL', true)
  },
  put() {
    $http.post("/member/become_agent", {
      invitation: ''
    })
      .then(({ data }) => {
        message.success(data || "操作成功！");
        agent.close()
        store.dispatch("refreshInfo");
        router.push({ name: "AgentIndex" });
      })
      .catch((error) => {
        let msg = error.response.data?.message
        message.error(msg || "发生错误！");
      });
  },
  close() {
    agent.visible = false
    agent.invitation = ''
    store.commit('SETAGENTMODEL', false)
  }
})
// console.log(agent.visible, '------------------------')
const { invitation } = toRefs(agent)
</script>
<style lang="scss">
.agentPcModel {

  .el-dialog__body {
    width: 100%;
    min-height: 470px !important;
    border-radius: 24px;
    position: relative;

    .click-box {
      padding: 0 70px !important;
      height: 240px !important;

      .answer {
        margin-top: 24px !important;
      }

      p {
        margin-bottom: 6px;
      }
    }
  }
}

.agentModel {
  border-radius: 24px !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__footer {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;
    border: 0;

    .close {
      // position: absolute;
      // bottom: -12px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #fff;
      color: #3D3D3D;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .el-dialog__body {
    width: 100%;
    min-height: 380px;
    background-image: linear-gradient(180deg, #FEA875 0%, #FFF1ED 97%);
    border-radius: 24px;
    position: relative;

    .click-box {
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
      height: 215px;
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .answer {
        margin-top: 16px;

        span {
          color: #FF704C;
          cursor: pointer;
        }
      }

      p {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #222222;
        font-weight: 400;
        display: block;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 36px;
          width: 32px;
          border-radius: 4px;
          height: 3px;
          background-color: #FEA875;
        }
      }

      input {
        width: 100%;
        height: 45px;
        background: #F7F7F7;
        border: none;
        border-radius: 40px;
        text-align: center;
        margin: 16px 0 24px;
        box-shadow: none;
        outline: none;
        color: #000;

        &::placeholder {
          color: #D8D8D8;
        }
      }

      button {
        width: 100%;
        height: 45px;
        background-image: var(--btnbg4);
        border-radius: 40px;
        color: #fff;
        font-size: 16px;
        border: 0;

        &:disabled {
          pointer-events: none;
          cursor: not-allowed;
          background-image: linear-gradient(90deg, #F7F7F7 0%, #F7F7F7 100%);
          // border: 1px solid #d6d6d6;
          color: #D8D8D8;
        }
      }
    }

    .modelbg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
    }
  }
}
</style>
