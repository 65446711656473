// import Cookies from 'js-cookie'

import { storage } from "@/utils/index";
import store from "@/store";
import router from "@/router";
const TokenKey = "Token";
//写cookies
function setCookie(name, value) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
}

//读取cookies
function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

//删除cookie
function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie =
      name + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/";
}

export function getToken() {
  return getCookie(TokenKey);
  // return window.sessionStorage.getItem(TokenKey);
}

export function setToken(token) {
  return setCookie(TokenKey, token);
  // return window.sessionStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return delCookie(TokenKey);
  // return window.sessionStorage.removeItem(TokenKey);
}
//暂时没用
export function isLoginAuth() {
  let userInfo = storage.getSession("tpl01UserInfo") || {};
  //
  if (userInfo?.isTourists) {
    return userInfo?.isTourists == 1 ? false : true;
  } else {
    return false;
  }
}
export function isTourists(val = "query") {
  let siteInfo = storage.getSession("tpl01SiteInfo") || {};
  let userInfo = storage.getSession("tpl01UserInfo") || {};
  if (val == "query") {
    if (userInfo?.isTourists) {
      return userInfo?.isTourists == 1 ? true : false;
    } else {
      return false;
    }
  } else if (val == "h5") {
    // userInfo.isTourists = 1;
    // siteInfo.visitor = 1
    if (getToken() && userInfo?.isTourists == 1 && siteInfo?.visitor == 1) {
      console.log()
      let route = router.currentRoute?.value.name || 'MemberHome'
      store.commit('SETLOGINPATH', route)
      router.push({ name: 'MemberLogin' })
      // throw '不允许访问'
      return true;
    } else {
      return false;
    }
  } else {
    if (getToken() && userInfo?.isTourists == 1 && siteInfo?.visitor == 1) {
      store.commit("SETLOGINPATH", "/reload");
      store.commit("SETISLOGIN", true);
      return true;
    } else {
      return false;
    }
  }
}
