<template>
  <div :id="domID"></div>
</template>
<script setup>
import md5 from "md5";
import {
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  nextTick,
  watch,
} from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();

let router = useRouter();

const props = defineProps({
  position: { default: "" },
});
const state = reactive({
  domID: 0,
});
const { domID } = toRefs(state);
const FN = {
  doPos() {
    if (props.position) {
      state.domID = md5(props.position + Math.random() * 10);
      // console.log(store.state.sitePub,'-124124---', props.position)
      let data = store.state.sitePub[props.position] || [];
      if (data.length === 0) return;
      let resp = showmylist(data);
      // console.log(resp,'-124124---', props.position)
      let dom = document.createElement("div");
      dom.innerHTML = resp;
      let domCn = dom.childNodes || [];
      let domScriptSrc = [];
      let domScriptStr = [];
      let domHtmlStr = [];
      for (let i = 0; i < domCn.length; i++) {
        if (domCn[i].nodeName == "SCRIPT") {
          // src
          if (domCn[i].src) {
            domScriptSrc.push(domCn[i].src);
          }
          // text outerText
          if (domCn[i].outerText) {
            domScriptStr.push(domCn[i].outerText);
          }
        } else {
          domHtmlStr.push(domCn[i].outerHTML || domCn[i].textContent);
        }
      }

      let respScript = [];
      for (let i = 0; i < domScriptSrc.length; i++) {
        let script = document.createElement("script");
        script.src = domScriptSrc[i];
        script.type = "text/javascript";
        respScript.push(script);
      }
      for (let i = 0; i < domScriptStr.length; i++) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = domScriptStr[i];
        respScript.push(script);
      }
      nextTick(function () {
        let ddom = document.getElementById(state.domID);
        if (ddom) {
          let respDom = ddom.parentNode;
          respDom.innerHTML = domHtmlStr.join("");
          for (let i = 0; i < respScript.length; i++) {
            respDom.appendChild(respScript[i]);
          }
        } else {
        }
      });
    }
  },
};
// onMounted(() => {
// 	nextTick(()=>{
// 		FN.doPos()
// 	})
// })
watch(
  () => store.state.sitePub,
  (n, o) => {
    FN.doPos();
  },
  { immediate: true, deep: true }
);
</script>
