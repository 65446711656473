<template>
	<router-view class="app" :key="$store.state.pageKey"></router-view>
</template>
<script setup>
import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
let router = useRouter();
watch(() => router, (n, o) => {
  store.commit("PageKey")
}, { immediate: true, deep: true })
</script>
<style>
* {
  box-sizing: border-box;
}

body {
  background: #fff;
  position: relative;
}

.app {
  background-color: #fff;
}
</style>
