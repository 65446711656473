import axios from "axios";
import { ElNotification } from "element-plus";
import { storage, throttle } from "@/utils/index";
import { getToken, removeToken, setToken } from "@/utils/auth";
import router from "@/router";
import store from "@/store";
import Axios from "axios";
let service = null;

function apiHandler(domain) {
  service = axios.create({
    baseURL: domain || location.origin,
    // withCredentials: true,
    timeout: 15000,
  });

  // request interceptor
  service.interceptors.request.use(
    async (config) => {
      // const localToken =  window.sessionStorage.getItem('query-token')

      if (getToken()) {
        config.headers["Authorization"] = getToken() || "";
      }
        // if (config?.baseurl) {
        //   config.baseURL ='';
        // }
      return config;
    },
    (error) => {
      //  // for debug
      return Promise.reject(error);
    }
  );

  // response interceptor
  service.interceptors.response.use(
    (response) => {
      // if (process.env.NODE_ENV == 'development') {
      //   let uri = response.config.url || ""
      //   if (uri.indexOf("/vod") != -1) {
      //     let data = response.data || {}
      //     if (data.list && data.list.length > 0) {
      //       data.list.forEach(function (item) {
      //         item.verticalPoster = "/debug/p2880655515.webp"
      //         item.horizontalPoster = "/debug/p2880655515.webp"
      //         item.screenshot = "/debug/p2880655515.webp"
      //       })
      //       response.data = data
      //     }
      //   }
      //   if (uri.indexOf("/stars") != -1) {
      //     let data = response.data || {}
      //     if (data.list && data.list.length > 0) {
      //       data.list.forEach(function (item) {
      //         item.avatar = "/debug/p2880655515.webp"
      //       })
      //       response.data = data
      //     }
      //   }
      // }
      return response;
    },
    (error) => {
      httpErrorStatusHandle(error);
      return Promise.reject(error);
    }
  );

  return service;
}

/**
 * 处理异常
 * @param {*} error
 */
function httpErrorStatusHandle(error) {
  // const { config } = error?.response;
  // 处理被取消的请求
  if (axios.isCancel(error))
    return console.error("请求的重复请求：" + error.message);
  let message = "";
  if (error && error.response) {
    let msg = error.response?.data?.message;
    //
    switch (error.response.status) {
      case 302:
        message = "接口重定向了！";
        break;
      case 400:
        message = msg || "参数不正确！";
        break;
      case 401:
        message = msg || "您未登录，或者登录已经超时，请先登录！";
        break;
      case 402:
        let mesList = Array.prototype.map.call(
          document.querySelectorAll(".err-mes"),
          (ele) => ele.innerText
        );
        if (mesList.includes(error.response.data.message)) {
          retrun;
        }
        let body = document.getElementsByTagName("body")[0];
        let div = document.createElement("div");
        div.className = "err-mes";

        div.innerHTML = error.response.data.message;
        body.appendChild(div);
        break;
      case 403:
        message = msg || "您没有权限操作！";
        break;
      case 404:
        message = msg || `请求地址出错: ${error.response.config.url}`;
        break; // 在正确域名下
      case 408:
        message = "请求超时！";
        break;
      case 409:
        message = "系统已存在相同数据！";
        break;
      case 500:
        message = "服务器内部错误！";
        break;
      case 501:
        message = "服务未实现！";
        break;
      case 502:
        message = "网关错误！";
        break;
      case 503:
        message = "服务不可用！";
        break;
      case 504:
        message = "服务暂时无法访问，请稍后再试！";
        break;
      case 505:
        message = "HTTP版本不受支持！";
        break;
      default:
        message = "异常问题，请联系管理员！";
        break;
    }
  }
  if (error.message.includes("timeout")) message = "网络请求超时！";
  if (error.message.includes("Network"))
    message = window.navigator.onLine ? "服务端异常！" : "您断网了！";
  //弹窗
  // aMessage.error(message)
  if (
    [401, 403].indexOf(error.response?.status) >= 0 ||
    message == "会员数据不存在"
  ) {
    removeToken();
    storage.setSession("isTourists", false);
    window.sessionStorage.removeItem("tpl01UserInfo");
    router.push({ path: "/" });

    // storage.clearAllSession()
    // refreshToken();
    // location.reload()
  }
}
// const refreshToken = throttle(async function () {
//   if (getToken()) return false;
//   let api = storage.getSession("tpl01Api") || store.state.api;
//   var reopt = {
//     method: "POST",
//     url: api + "/openapi/member/tourists/register",
//   };
//   let { data } = await Axios(reopt);
//   storage.setSession("isTourists", true);
//   setToken(data.token);
//   store.commit("SETUSERINFO", data.userInfo);
//
// }, 500);

export default apiHandler;
