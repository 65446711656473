<template>
  <template v-if="!$route.path.includes('/member') && !$route.path.includes('/agent')">
    <div>
      <Position position="generalCustom" />
    </div>
    <div>
      <Position position="indexpic" />
    </div>
    <div class="clear"></div>
  </template>
  <footer>
    <div class="layout">
      <!-- <p>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
			</p>
			 本站资源由 <a href="{{$store.state.siteInfo.TechnicalSupport}}" target="_blank" style="color:red">{{$store.state.siteInfo.TechnicalSupport}}</a> 提供 -->
      <div class="desc">
        本站内容均从互联网收集而来，仅供交流学习使用，版权归原创者所有如有侵犯了您的权益，尽请通知我们，本站将及时删除侵权内容。
      </div>
      <div class="copyright">
        Copyright @ 2022 {{ $store.state.siteInfo.name }}
        {{ $store.state.siteInfo.Forever }} 版权所有
      </div>
    </div>
    <ul class="tool">
      <li v-if="isWs" @click="myTimeupdate()" class="ser">
        <div class="svg" :class="{ quivered: newMesNum > 0 }">
          <img :src="$Svg('kefu.svg')" alt="客服" />
        </div>
        <p>客服</p>
        <span class="remind" v-if="newMesNum">{{ newMesNum }}</span>
      </li>
      <li v-if="apkUrl">
        <div id="qrcodeAndroid" class="qrcode"></div>
        <a download="APK">
          <div class="svg"><img :src="$Svg('apk.svg')" alt="APK" /></div>
          <p>APK</p>
        </a>
      </li>
      <li v-if="iosUrl">
        <div id="qrcodeIOS" class="qrcode"></div>
        <a download="IOS">
          <div class="svg"><img :src="$Svg('ios.svg')" alt="IOS" /></div>
          <p>IOS</p>
        </a>
      </li>
      <li @click="FN.toTop()">
        <div class="svg">
          <img class="roate" :src="$Svg('top.svg')" alt="TOP" />
        </div>
      </li>
    </ul>
    <!-- <div class="service-box" v-if="isWs && !getToken()">
      <div class="ser-header">
        <img src="@/assets/image/defAvator.png">
        <span>客服小助手{{ isWs }}</span>
        <p class="ser-close" @click="isMessage = !isMessage"></p>
      </div>
      <div class="ser-message" v-show="isMessage">
        <div class="item">
          <img src="@/assets/image/defAvator.png">
          <div>
            <p style="text-align: left;margin: 0 0 8px;">客服小助手</p>
            <div class="content">您好，请点击 <span class="login" @click="FN.login()">登录</span> ，小助手将为您提供更优质的服务哦！</div>
          </div>
        </div>
      </div>
    </div> -->
  </footer>
  <div class="clear"></div>
  <template v-if="$route.name != 'Member'">
    <div style="display: none" v-if="$route.name != 'Index'">
      <Position position="count" />
    </div>
    <div style="display: none" v-else>
      <Position position="indexcount" />
    </div>
  </template>
  <div id="syncCustomer" style="display: none"></div>
</template>
<script setup>
import {
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  nextTick,
  watch,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getToken } from "@/utils/auth";
import { storage, debounce, throttle } from "@/utils/index";
let router = useRouter();
const store = useStore();

// 获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

const state = reactive({
  isMessage: true,
  isWs: false,
  newMesNum: 0,
});
// watch(() => store.getters.isWs, val => state.isWs = val)
const { apk, isWs, newMesNum } = toRefs(state);

const iosUrl = computed(() => store.state.iosUrl)
const apkUrl = computed(() => store.state.apkUrl)

const FN = {
  // 回到顶部
  toTop() {
    $("body,html").animate({ scrollTop: 0 }, 480);
  },

  login() {
    store.commit("SETLOGINPATH", "/ws");
    store.commit("SETISLOGIN", true);
  },
  fetchCustomer() {
    // let tags = document.getElementById('blzxMinChatWindowDiv')
    // if (tags === null) {
    storage.setSession("wsApi", false);
    $http
      .get("/template/customer", {})
      .then((res) => {
        let data = res.data || {};
        if (data?.js) {
          state.isWs = true;
          storage.setSession("wsApi", data.api);
          nextTick(() => {
            document.querySelector("#syncCustomer").innerHTML = data.js;
            Array.prototype.forEach.call(
              document.querySelector("#syncCustomer").children,
              (ele) => {
                // 标签复制
                const tag = document.createElement(ele.tagName.toLowerCase());
                if(document.querySelector('#syncCustomerDom')) return
                tag.id = 'syncCustomerDom'
                // 内容复制
                tag.innerHTML = ele.innerHTML;
                // 属性复制
                Array.prototype.forEach.call(ele.attributes, (attr) => {
                  tag.setAttribute(attr.name, attr.value);
                });
                document.body.appendChild(tag);
              }
            );
            if (getToken()) {
              store.dispatch("refreshInfo");
              console.log(store.getters.userInfo);
            }
            // setTimeout(() => {
            //   let userInfo = storage.getSession("tpl01UserInfo") || {};
            //   let isWsModel = storage.getSession("isWsModel")
            //   if (isWsModel) return false
            //   if (getToken() && userInfo?.isTourists != 1) {
            //     serCtr.open(store.getters.userInfo.id, data.api)
            //     storage.setSession("isWsModel", true)
            //   }
            // }, 500)
          });
        }
      })
      .catch(() => {
        state.isWs = false;
      });

    // }else{
    //   document.getElementById('blzxMinChatWindowDiv').style.display = 'block'
    // }
  },

  // 客服系统
  toCustomer() {
    let userInfo = storage.getSession("tpl01UserInfo") || {};
    // console.log(userInfo)
    // if (!(getToken() && userInfo?.isTourists != 1)) {
    if (!getToken()) {
      store.commit("SETLOGINPATH", "/ws");
      store.commit("SETISLOGIN", true);
      return false;
    } else {
      if (userInfo?.id) {
        // storage.getSession("wsApi")
        serCtr.open(store.getters.userInfo.id);
      } else {
        store.dispatch("refreshInfo");
        serCtr.open(store.getters.userInfo.id);
      }
    }
    setSerEvent();
  },

  formatUri(path){
    if(!path) return ""
    if(path.indexOf("/") != 0){
      path = "/" + path;
    }
    if(path.indexOf("http") == -1){
      path = location.origin + path
    }
    return path;
  },

  //获取apk
  fetchApk() {
    // console.log(apkUrl.value, store.state.apkUrl) 
    if(apkUrl.value){
      $('#qrcodeAndroid').empty()
      try {
        // console.log($('qrcodeAndroid'))
        new QRCode("qrcodeAndroid", {
          text: FN.formatUri(apkUrl.value),
          width: 104,
          height: 104,
          colorDark: "#29342a",
          colorLight: "#ffffff",
          correctLevel: 3
        });
      } catch (error) {}
    }else{
      $('#qrcodeAndroid').hide()
    }
  },
  //获取apk
  fetchIos() {
    if(iosUrl.value.url){
      $('#qrcodeIOS').empty()
      try {
        new QRCode("qrcodeIOS", {
          text: FN.formatUri(iosUrl.value.url),
          width: 104,
          height: 104,
          colorDark: "#29342a",
          colorLight: "#ffffff",
          correctLevel: 3
        });
      } catch (error) {}
    }else{
      $('#qrcodeIOS').hide()
    }
  }
};
//防抖处理
const myTimeupdate = debounce(FN.toCustomer, 500, true);
onMounted(() => {
  FN.fetchCustomer();
  setSerEvent();
  setTimeout(() => {
    FN.fetchApk()
    FN.fetchIos()
  }, 500);
});
function setSerEvent() {
  if (window.serCtr) {
    serCtr.events.message = function (mes, num, display) {
      if (display === "none") {
        newMesNum.value = num;
      } else {
        newMesNum.value = 0;
      }
    };
    serCtr.events.opened = function () {
      newMesNum.value = 0;
    };
    serCtr.events.narrow ||= function () {
      newMesNum.value = 0;
    };
  }
}
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  background: rgba(231, 231, 231, 0.6);
  text-align: center;
  margin-top: 30px;

  .layout {
    display: block;
    margin: 0 auto;
    width: var(--width);
    height: 100%;
    padding: 30px 0;

    // p{
    // 	padding: 45px 0;
    // 	a{
    // 		color: #666;
    // 		font-weight: 400;
    // 		margin-right: 30px;
    // 		font-size: 16px;
    // 		&:last-child{
    // 			margin-right: 0;
    // 		}
    // 	}
    // }
    .desc {
      padding-top: 30px;
      padding-bottom: 15px;
      font-size: 14px;
      color: #999999;
      letter-spacing: 1px;
      font-weight: 400;
    }

    .copyright {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      letter-spacing: 1px;
      font-weight: 400;
      padding-bottom: 15px;
    }
  }

  .service-box {
    position: fixed;
    right: 0vw;
    bottom: 00vh;
    background: #fff;
    width: 400px;
    // max-height: 200px;
    z-index: 99;
    // transition: max-height .5s ease-in-out;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    .ser-message {
      height: 309px;
      background-color: #f5f5f5;
      color: #333333;
      font-size: 12px;
      padding: 16px;
      overflow: auto;

      .item {
        display: flex;
        gap: 8px;
        margin-bottom: 16px;

        .login {
          color: #5b8ff9;
          cursor: pointer;
        }

        .content {
          background: white;
          padding: 6px 10px;
          border-radius: 4px;
          line-height: 22px;
          position: relative;
          display: inline-flex;
        }

        img {
          border-radius: 50%;
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .ser-header {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #5b8ff9;
    padding: 0 16px;
    gap: 0 16px;
    color: #fff;

    .ser-close {
      box-sizing: initial;
      margin-left: auto;
      width: 16px;
      height: 2px;
      background-color: white;
      cursor: pointer;
      padding: 10px;
      background-clip: content-box;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

@keyframes blink {

  0%,
  49% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

.tool {
  position: fixed;
  right: 0;
  bottom: 10vh;
  background: #fff;
  // width: 50px;
  width: 80px;
  border: 1px solid #eee;
  z-index: 10;
  transition: all 0.2s;
  border-radius: 3px 0 0 3px;

  // overflow: hidden;
  // &:hover {
  //   width: 60px;
  // }
  .quivered {
    animation: 0.4s linear 0.4s infinite alternate blink;
  }

  .remind {
    background-color: #f25a5a;
    border-radius: 8px;
    text-align: center;
    color: white;
    padding: 0 4px;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 6px;
  }

  li {
    position: relative;
    padding: 5px 0;
    cursor: pointer;

    .qrcode {
      width: 120px;
      height: 120px;
      position: absolute;
      // border: 1px solid red;
      top: -30px;
      right: 90px;
      display: none;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      padding: 8px;
      background: #fff;
      // &::before{
      //   position: absolute;
      //   width: 10px;
      //   height: 10px;
      //   background-color: #fff;
      //   border-radius: 2px;
      //   box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      //   z-index: 1;
      //   top: 55px;
      //   right: -6px;
      //   content: " ";
      //   transform: rotate(45deg);
      // }
    }

    &.ser {
      position: relative;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      border-bottom: 1px solid #eee;
    }

    &:hover {
      background-image: var(--theme);

      .qrcode {
        display: block;
      }

      p {
        color: #fff;
      }

      img {
        filter: drop-shadow(-25px 0px 0px #fff);
        -webkit-filter: drop-shadow(-25px 0px 0px #fff);

        &.roate {
          filter: drop-shadow(-25px 0px 0px #fff);
          -webkit-filter: drop-shadow(-25px 0px 0px #fff);
        }
      }
    }

    img {
      filter: drop-shadow(-25px 0px 0px var(--svg));
      -webkit-filter: drop-shadow(-25px 0px 0px var(--svg));

      &.roate {
        width: 32px;
        height: 24px;
        filter: drop-shadow(-25px 0px 0px var(--svg));
        -webkit-filter: drop-shadow(-25px 0px 0px var(--svg));
      }
    }

    p {
      margin-top: 3px;
      color: var(--svg);
      font-size: 12px;
    }
  }
}</style>
