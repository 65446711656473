<template>
  <template v-if="$route.name != 'Member'">
    <div class="section-banner">
      <!-- <Position position="banner" v-if="$route.name == 'Index'" /> -->
      <Position position="top" />
    </div>
    <div class="clear"></div>
    <!-- 浮动 -->
    <div>
      <Position position="float" />
    </div>
    <div class="clear"></div>
  </template>
  <header>
    <div class="layout">
      <div class="logo">
        <router-link :to="{ name: 'Index' }">
          <!-- <img :src="`${store.getters.api + store.state.siteOtherInfo.logo}`" v-if="$store.state.siteOtherInfo.logo" /> -->
          <img :src="$Svg('logo.png')" />
          <!-- <span>ABCDEFG.COM</span> -->
          <span>{{ store.state.siteInfo.SiteURL }}</span>
          <!-- <span v-else>1</span> -->
          <!-- <div class="siteInfo">
						<p style="font-size: 18px;">{{ store.state.siteInfo.Title }}</p>
						<p>{{ domain }}</p>
					</div> -->
        </router-link>
      </div>
      <template v-for="(vo, idx) in menus">
        <div class="menu" v-if="vo.meta.menu">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <!-- <div class="swiper-slide">
								<router-link :to="{ name: 'Index' }">首页</router-link>
							</div> -->
              <template v-for="(sub, idy) in vo.children">
                <div class="swiper-slide" v-if="sub.meta.menu" :title="sub.meta.title" :_id="sub.name">
                  <router-link :to="{ name: sub.name }" :class="{ on: sub.name == onName }" v-if="sub.meta.menu">
                    {{ sub.meta.title }}</router-link>
                </div>
              </template>
              <template v-for="(sub, idz) in $store.state.cateList">
                <template v-for="(vosub, idj) in sub.children">
                  <div class="swiper-slide" :title="vosub.name" :_id="vosub.id">
                    <router-link :to="{ name: 'CateList', params: { cid: vosub.id } }"
                      :class="{ on: vosub.id == onName }">
                      {{ vosub.name }}
                    </router-link>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </template>
      <div class="tool">
        <div class="input">
          <input placeholder="输入片名关键字" type="text" v-model="keyword" @keydown.enter="
            $router.push({ name: 'Search', query: { keyword: keyword } })
            " />
          <span class="search" @click="
            $router.push({ name: 'Search', query: { keyword: keyword } })
            ">
            <img :src="$Svg('search.svg')" />
            搜全网
          </span>
          <div class="select">
            <ul>
              <template v-for="(vo, idx) in hots">
                <li>
                  <a @click="FN.toPlayer(vo)" :title="vo.name">
                    <el-tag :type="idx < 2 ? 'danger' : 'warning'" effect="dark" size="small">
                      {{ idx < 2 ? "热" : "新" }} </el-tag>{{ vo.name }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <el-popover v-if="getToken()" popper-class="headerPop" placement="bottom" :zIndex="10002" :width="150"
          trigger="click">
          <template #reference>
            <img v-if="$store.state.userInfo.headImg" class="avatur cover"
              :src="formatUrl($store.state.userInfo.headImg)" />
            <span v-else class="menber">
              <img :src="$Svg('header.svg')" />
            </span>
          </template>
          <div class="menuItem">
            <div @click="goUser()">个人中心</div>
            <div @click="toAgent()" v-if="state.Agent">代理系统</div>
            <div @click="changeuser()" v-if="isTourists()" style="border: 0">
              切换登录
            </div>
            <div @click="loginOut" v-else style="border: 0">退出登录</div>
          </div>
        </el-popover>
        <span v-else class="menber" @click="goUser()">
          <img :src="$Svg('header.svg')" />
        </span>
      </div>
    </div>
  </header>
  <div class="clear"></div>
  <div>
    <Position position="bottom" />
  </div>
  <tagList v-if="cid && !detailId" :id="cid"></tagList>

  <template v-if="$route.name != 'Member'">
    <div class="clear"></div>
    <div>
      <Position position="nextdown" />
    </div>
    <div class="clear"></div>
    <div>
      <Position position="menudown" />
    </div>
    <div class="clear"></div>
    <div class="three">
      <Position position="topthree" />
    </div>
    <div class="clear"></div>
    <!-- <div>
      <Position position="mytop" v-if="$route.name != 'Index'" />
    </div> -->
    <div class="clear"></div>
  </template>
  <agent type="pc" v-if="!isMobile()" />
  <invite type="pc" v-if="!isMobile() && !$store.getters.isLogin" />
  <pay />
  <login />
  <paying />
</template>
<script setup>
import invite from "@/components/Agent/invite.vue";
import agent from "@/components/Agent/dialogs.vue";
import pay from "@/components/Pay/index.vue";
import login from "@/components/Login/index.vue";
import tagList from '@/components/tagList.vue'
import paying from "@/components/Pay/pay.vue";
import {
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  nextTick,
  watch,
  computed,
} from "vue";
import { AppRouter } from "@/router/index.js";
import { useRouter, useRoute } from "vue-router";
import { getToken, isTourists } from "@/utils/auth";
import { formatUrl, isMobile } from "@/utils/index";
import { useStore } from "vuex";
import { ElMessage as message } from "element-plus";
let router = useRouter();
const route = useRoute()
const cid = router.currentRoute.value.params?.cid
const detailId = router.currentRoute.value.params?.id
const store = useStore();
const changeuser = () => {
  store.commit("SETLOGINPATH", "Member");
  store.commit("SETISLOGIN", true);
};
const loginOut = () => {
  store.dispatch("logout");
  location.reload();
};
const goUser = () => {
  if (getToken()) {
    const routeUrl = router.resolve({ name: "Member" });
    window.open(routeUrl.href, "_blank");
    // router.push({ name: "Member" });
  } else {
    store.commit("SETLOGINPATH", "Member");
    store.commit("SETISLOGIN", true);
  }
};

//获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

const state = reactive({
  userInfo: computed(() => store.getters.userInfo) || {},
  menus: [],
  hots: [],
  onName: "",
  domain: "",
  swiper: null,
  invitation: "",
  Agent: false,
  domainList: []
  // navigations: {},
  // userInfo: computed(() => store.getters.userInfo) || {},
});
const { menus, onName, hots, domain, invitation } = toRefs(state);
const keyword = ref(null);

const FN = {
  toPlayer(item) {
    let cate = item.category || [];
    let cid = 0;
    for (let i = 0; i < cate.length; i++) {
      cid = cate[i].id;
    }
    if (cid) {
      router.push({ name: "VodInfo", params: { cid: cid, id: item.id } });
    }
  },
  getList() {
    let data_opt = {
      limit: store.state.num.vodRecommendNum || 10,
      label: route.query.t
      // random:true
    };
    // store.commit("Loading",true)
    // 获取影片列表
    $http
      .get("/template/vod", {
        params: data_opt,
        label: router.getCurrentInstance?.query?.t
      })
      .then((res) => {
        let data = res.data || [];
        state.hots = data.list || [];
      })
      .then((res) => {
        // store.commit("Loading",false)
      });
    $http.get("/siteConfig/domain").then(({ data }) => {
      if (data != '') {
        store.commit('SETISITENAME', data)
      } else {
        store.commit('SETISITENAME', window.location.hostname)
      }
    });
  },
  getHostName() {
    state.domain = location.hostname;
  },
  navigation() {
    if (state.menus.length > 0) {
      // console.log('导航信息：',store.state.cateList,state.menus)
      let index = 0;
      let routerMap = [];
      // 构建导航信息
      for (let vo in state.menus) {
        let menu = state.menus[vo];
        if (menu.meta) {
          for (let child_index in menu.children) {
            let child = menu.children[child_index];
            if (child.meta.menu) routerMap.push(child);
          }
        }
      }
      for (let vo in store.state.cateList) {
        let cate = store.state.cateList[vo];
        for (let child_index in cate.children) {
          let child = cate.children[child_index];
          routerMap.push(child);
        }
      }
      routerMap.forEach((el, t) => {
        if (el.id == state.onName || el.name == state.onName) index = t;
      });
      // console.log('组合导航信息：',routerMap,index)
      nextTick(function () {
        state.swiper = new Swiper(".mySwiper", {
          slidesPerView: 6.5,
          spaceBetween: 10,
        });
        if (index > 4) {
          state.swiper.slideTo(index, 0, true);
        }
      });
    }
  },
  //获取代理系统
  getAgent() {
    $http.get("/member/agent/open").then(({ data }) => {
      state.Agent = data;
      console.log(data, "代理系统");
    });
  },
};
const toAgent = () => {
  if (isTourists("click")) return false;
  if (state.userInfo?.isAgent) {
    const routeUrl = router.resolve({ name: "Agent" });
    window.open(routeUrl.href, "_blank");
    // router.push({ name: "Agent" });
  } else {
    store.commit('SETAGENTMODEL', true)
  }
};
const fetchUserInfo = () => {
  $http.get("/member/info").then(({ data }) => {
    store.commit("SETUSERINFO", data);
  });
};

onMounted(() => {
  state.menus = AppRouter();
  FN.getHostName();
  FN.getList();
  FN.getAgent();
  //当菜单为分类时，更改titke标签
  for (let vo in store.state.cateList) {
    let cate = store.state.cateList[vo];
    for (let child_index in cate.children) {
      let child = cate.children[child_index];
      if (child.id == state.onName) {
        document.title = child.name;
      }
    }
  }
  // FN.navigation();
});

watch(
  () => router,
  (n, o) => {
    let route = n.currentRoute.value || {};
    keyword.value = route.query.keyword || "";
    if (route.params.cid) {
      state.onName = route.params.cid;
    } else {
      switch (route.name) {
        case "Star":
        case "StarInfo":
          state.onName = "People";
          break;
        case "Topic":
        case "TopicInfo":
          state.onName = "Topic";
          break;
        default:
          state.onName = route.name;
      }
    }
  },
  { immediate: true, deep: true }
);
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  height: 72px;
  box-shadow: 0px 4px 20px 0px rgba(78, 78, 78, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  font-size: 16px;
  z-index: 10001;

  .layout {
    background-image: var(--theme);
    display: block;
    margin: 0 auto;
    width: var(--width);
    height: 100%;
    position: relative;

    .logo {
      float: left;
      height: 100%;
      width: 200px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      position: relative;

      .siteInfo {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 10px;
        color: #fff;
        font-weight: bold;
      }

      a {
        height: inherit;
        display: flex;
        align-items: center;

        img {
          width: auto;
          max-height: 80%;
        }
      }

      span {
        position: absolute;
        left: 60px;
        right: 0;
        bottom: 6px;
        text-align: center;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .tool {
      position: absolute;
      right: 10px;
      top: 15px;
      line-height: 40px;
      display: flex;

      .input {
        position: relative;
        width: 300px;
        height: 40px;
        background: #ffffff;
        border-radius: 20px;
        padding: 0 10px;
        color: #999;

        input {
          outline: none;
          width: 72%;
          height: 30px;
          border: 0;
          border-radius: 5px;
        }

        .search {
          position: absolute;
          top: 3px;
          right: 3px;
          height: 34px;
          padding: 0 15px;
          background-image: var(--search);
          border-radius: 20px;
          font-size: 14px;
          text-align: center;
          display: flex;
          align-items: center;
          color: #fff;
          cursor: pointer;

          img {
            margin-right: 5px;
          }
        }

        &:hover {
          .select {
            display: block;
          }
        }

        .select {
          position: absolute;
          left: 15px;
          top: 40px;
          width: 270px;
          box-shadow: 0px 4px 20px 0px rgba(78, 78, 78, 1);
          display: none;
          padding-top: 5px;
          max-height: 50vh;
          overflow-y: auto;

          ul {
            background: #fff;

            li {
              list-style-type: none;
              padding: 0 5px;
              font-size: 14px;
              border-bottom: 1px solid rgba(244, 141, 114, 0.3);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #999;
              font-weight: 400;
              cursor: pointer;
              opacity: 0.8;

              .el-tag {
                margin-right: 5px;
              }

              &:last-child {
                border-bottom: 0;
              }

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }

      .avatur {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-left: 20px;
        cursor: pointer;
      }

      .menber {
        cursor: pointer;
        margin-left: 20px;
        background-color: #fff;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        padding: 8px;
        text-align: center;

        a {
          display: inline-block;
        }

        img {
          // width: 100%;
          // height: 100%;
          width: 20px;
          transform: translateX(40px);
          filter: drop-shadow(-40px 0px 0px var(--svg));
          -webkit-filter: drop-shadow(-40px 0px 0px var(--svg));
        }
      }
    }

    .menu {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      max-width: calc(var(--width) - 500px);
      overflow: hidden;
      position: relative;
      width: 700px;

      a {
        color: #fff;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        border-radius: 20px;
        // width: 70px;
        height: 40px;
        padding: 0 20px;

        &.on {
          background-image: var(--on);
          border: 2px solid rgba(255, 255, 255, 1);
          box-shadow: 0px 4px 10px 0px rgba(130, 130, 130, 0.1);
        }
      }
    }

    .mySwiper {
      width: 100%;
      padding: 15px 10px;
      overflow: hidden;

      .swiper-wrapper {
        display: flex;
        grid-template-columns: auto auto auto auto auto auto auto auto auto;
        grid-gap: 0;
      }

      .swiper-slide {
        width: auto;
        text-align: center;
        white-space: nowrap;
        color: #fff;

        // /* Center slide text vertically */
        // display: -webkit-box;
        // display: -ms-flexbox;
        // display: -webkit-flex;
        // display: flex;
        // -webkit-box-pack: center;
        // -ms-flex-pack: center;
        // -webkit-justify-content: center;
        // justify-content: center;
        // -webkit-box-align: center;
        // -ms-flex-align: center;
        // -webkit-align-items: center;
        // align-items: center;
      }
    }
  }
}
</style>
