<template>
  <div class="tabs-box">
    <el-tabs v-model="activeKey" @tab-change="login.changeKey()">
      <el-tab-pane :label="titleText('短信')" v-if="siteInfo.smsOpen" :name="2"></el-tab-pane>
      <el-tab-pane :label="titleText('邮箱')" v-if="siteInfo.emailOpen" :name="3"></el-tab-pane>
      <el-tab-pane :label="titleText('账号')" :name="1"></el-tab-pane>
    </el-tabs>
  </div>
  <div style="margin-bottom: 1rem; text-align: center; color: red; z-index: 1;
    position: relative;" v-if="isXy">
    同意《个人信息保护政策》《服务协议》
  </div>
  <el-form :model="formState" ref="formRef" :rules="rules" class="defaultForm" :wrapper-col="{ span: 22 }"
    autocomplete="off">
    <el-form-item prop="account" :rules="[
      { required: true, message: `请输入${activeMap[activeKey - 1]}!` },
    ]">
      <div class="input-box bitian">
        <svg-icon name="zhanghao" color="#999" size="1.5rem" />
        <!-- <img :src="$Svg('icons/zhanghao.svg')" /> -->
        <el-input v-model="formState.account" :placeholder="`请输入${activeMap[activeKey - 1]}`" :bordered="false">
          <!-- <template v-if="activeKey == 2" #addonAfter>
              <el-select v-model:value="value" style="width: 80px;" :bordered="false">
                <el-select-option :value="1">+86</el-select-option>
                <el-select-option :value="2">+87</el-select-option>
              </el-select>
            </template> -->
        </el-input>
      </div>
    </el-form-item>
    <template v-if="activeKey == 1">
      <el-form-item prop="password">
        <div class="input-box bitian">
          <svg-icon name="mima" size="1.5rem" />
          <el-input type="password" show-password v-model="formState.password" placeholder="请输入密码" :bordered="false" />
        </div>
      </el-form-item>
      <el-form-item prop="confirm" v-if="isRegister">
        <div class="input-box bitian">
          <svg-icon name="mima" size="1.5rem" />
          <el-input type="password" show-password v-model="formState.confirm" placeholder="请二次确认密码" :bordered="false" />
        </div>
      </el-form-item>
    </template>
    <el-form-item v-else prop="code" :rules="[{ required: true, message: '请输入验证码!' }]">
      <div class="validate">
        <div class="input-box" style="width: 240px">
          <svg-icon name="yanzhengma" size="1.5rem" />
          <!-- <img :src="$Svg('icons/yanzhengma.svg')" /> -->
          <el-input
            onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
            v-model="formState.code" :maxlength="6" placeholder="请输入验证码" :bordered="false" />
        </div>
        <el-button type="primary" class="validatebtn" :disabled="count > 0 || !login.rulesRegular()
          " shape="round" @click="fetchCode">
          {{ count > 0 ? `${count}s后失效` : "发送验证码" }}
        </el-button>
      </div>
    </el-form-item>
    <el-form-item prop="inviteCode" v-if="store.state.isAgentOpen && isRegister && !shareIs">
      <div class="input-box">
        <svg-icon name="yanzhengma" size="1.5rem" />
        <el-input v-model="formState.inviteCode" placeholder="邀请码" />
      </div>
    </el-form-item>
  </el-form>
  <div class="content-box">
    <el-button type="primary" class="loginbtn" @click="trigger()" shape="round">
      {{ isRegister ? (isTourists() ? "绑定" : "注册") : "登录" }}
    </el-button>
    <el-radio @change="isXy = false" :label="true" v-model="checked">
      <span :style="[
        { fontSize: '12px' },
        { color: checked ? 'var( --fontcolor-m)' : '#D0D0D0' },
      ]">同意《个人信息保护政策》《服务协议》</span>
    </el-radio>
    <div class="desc">
      {{ isRegister ? "已" : "还没" }}有账号？去
      <span style="color: #ff8e61; cursor: pointer" @click="isRegister = !isRegister">{{ isRegister ? "登录" : isTourists()
        ? "绑定" : "注册" }}</span>
    </div>
    <invite @ok="login.submit()" />
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  reactive,
  ref,
  toRefs,
  getCurrentInstance,
  watch,
  nextTick,
} from "vue";
import invite from '@/components/Agent/invite.vue'
import { useRouter } from "vue-router";
import { storage, isPass, isMobile, getQueryVariable } from "@/utils/index";
import { setToken, isTourists } from "@/utils/auth";
import { useStore } from "vuex";
import { throttle, debounce } from "@/utils/index";
import { ElMessage } from "element-plus";
//@ts-ignore
import md5 from "md5";
const store = useStore();
//获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

const titleText = (val) => {
  let str = val
  let index = ['账号', '短信', '邮箱'].indexOf(val)
  let titleMap = ['设置密码', '短信验证', '邮箱验证']
  if (!state.isRegister) return str + '登录'
  return isTourists() ? titleMap[index] : val + '注册'
}
const router = useRouter();
const siteInfo = computed(() => store.state.siteInfo)

onMounted(() => {
  if (isTourists()) {
    state.isRegister = true;
    // state.formState.account = store.getters.userInfo?.name;
  }
  if (siteInfo.value.smsOpen) {
    state.activeKey = 2
  } else if (siteInfo.value.emailOpen) {
    state.activeKey = 3
  }
  state.isRegister = state.shareIs ? true : state.isRegister;
});


let query = getQueryVariable();
const state = reactive({
  activeKey: 1,
  checked: false,
  activeMap: ["账号", "手机号", "邮箱"],
  count: 0,
  countDown: null,
  isRegister: false, //false登录
  visible: false,
  formState: {
    // account: 'test',
    // password: '123456'
  },
  isXy: false,
  shareIs: storage.getSession("shareId") || query.share || null,
  rules: {
    account: [
      {
        required: true,
        trigger: "blur",
        validator: (rule, value, callback) => {
          if (state.activeKey == 1) {
            let reg = /^.{3,24}$/;
            // let reg = /^[a-zA-Z0-9]{3,24}$/;
            if (!reg.test(value)) {
              callback(new Error(`用户名由3~24字符组成!`));
            } else {
              callback();
            }
          } else {
            if (!value?.trim()) {
              callback(
                new Error(`请输入${state.activeMap[state.activeKey - 1]}!`)
              );
            } else {
              callback();
            }
          }
        },
      },
    ],
    password: [
      {
        required: true,
        message: '密码不能为空!',
        trigger: 'blur'
      },
      {
        min: 6,
        max: 20,
        message: '密码长度为6~20个字符',
        trigger: 'blur'
      }
    ],
    confirm: [
      {
        required: true,
        trigger: "blur",
        validator: (rule, value) => {
          if (value?.trim()) {
            if (value != state.formState.password && state.formState.confirm) {
              return Promise.reject("两次密码不一致！");
            } else {
              return Promise.resolve();
            }
          } else {
            return Promise.reject("密码不能为空！");
          }
        },
      },
    ],
  },
});
const formRef = ref(null);
const login = {
  async submit() {
    const after = (data) => {
      if (data.token) {
        setToken(data?.token);
      }
      //@ts-ignore  断开socket连接
      if (window.serCtr?.socket) window.serCtr.close()
      store.commit("SETUSERINFO", data.userInfo);
      if (store.state.loginToPath == "/current") {
        store.dispatch("refreshInfo");
        store.commit("SETISLOGIN", false);
      } else if (store.state.loginToPath == "/reload") {
        store.commit("SETISLOGIN", false);
        location.reload();
      } else if (store.state.loginToPath == "/h5Login") {
        router.replace({
          path: store.state.h5LoginReturnUrl,
          query: getQueryVariable() || {},
        });
      } else if (
        store.state.loginToPath == "/ws" &&
        storage.getSession("wsApi")
      ) {
        try {
          window.serCtr ? serCtr.open(store.getters.userInfo.id, storage.getSession("wsApi")) : "";
          store.commit("SETWS", false);
        } catch (e) { }
      } else {
        if (isMobile()) {
          console.log(store.state.loginToPath, "跳转的地址");
          router.replace({
            name: store.state.loginToPath || 'MemberHome',
            query: getQueryVariable() || {},
          });
        } else {
          console.log(store.state.loginToPath, "跳转的地址");
          router.push({ name: store.state.loginToPath });
        }
        //   isMobile() //h5跳转
        //     ? router.replace({
        //         name: store.state.loginToPath,
        //         query: getQueryVariable() || {},
        //       })
        //     : router.push({ name: store.state.loginToPath });
      }
      store.commit("SETISLOGIN", false);
    };
    const errorMsg = (val) => {
      let msg = val.response?.data.message || "操作失败！"
      ElMessage({
        type: "error",
        message: msg,
      });
    }
    //账号密码登录
    if (state.activeKey == 1) {
      let obj = {
        userName: state.formState.account,
        password: md5(state.formState.password),
        confirmPassword: md5(state.formState.password),
        resolving: `${window.screen.width}*${window.screen.height}`,
        from: state.isRegister ? (isTourists() ? 2 : 1) : 3
      };
      //注册
      if (state.isRegister) {
        //邀请码
        obj["inviteCode"] = state.shareIs ? state.shareIs : state.formState.inviteCode;
        // await $http.post("/member/register", obj);
        $http.post("/member/register", obj)
          .then(() => {
            state.isRegister = false;
            ElMessage.success("注册成功！");
            login.submit();
          })
          .catch(v => errorMsg(v))
      } else {
        obj["id"] = "1";
        obj["captcha"] = "124121";
        // let { data } = await $http.post("/member/login", obj);
        // after(data);
        $http.post("/member/login", obj)
          .then(({ data }) => after(data))
          .catch(v => errorMsg(v))
      }
    } else {
      //其他登录
      let obj = {
        code: state.formState.code,
        userName: state.formState.account,
        verifyType: state.activeKey == 2 ? 1 : 2,
        resolving: `${window.screen.width}*${window.screen.height}`,
        from: state.isRegister ? (isTourists() ? 2 : 1) : 3
      };
      if (state.isRegister) obj["inviteCode"] = state.shareIs ? state.shareIs : state.formState.inviteCode;
      $http
        .post(state.isRegister ? "/member/verify/register" : "/member/verify/login", obj)
        .then(({ data }) => after(data))
        .catch(v => errorMsg(v))
    }
  },
  finish() {
    if (!state.checked) {
      state.isXy = true;
      // ElMessage.warning('请勾选协议！')
      return false;
    }
    formRef.value.validate((valid, fields) => {
      if (valid) {
        if (state.isRegister && state.formState.inviteCode) {
          storage.setSession('inviteCodeTpl01', state.formState.inviteCode)
          store.commit('SETCONFIRMMODEL', true)
        } else {
          login.submit();
        }
      } else {
        console.log('校验失败！', fields)
      }
    });
  },
  changeKey() {
    //清楚计时器
    state.formState.account = "";
    if (state.countDown) {
      clearInterval(state.countDown);
      state.count = 0;
      state.formState = {};
    }
    formRef.value.clearValidate();
  },
  rulesRegular() {
    let key = state.activeKey == 2
      ? /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      : /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
    return key.test(state.formState.account)
  },
};
let timer = null;
const trigger = () => {
  let firstClick = !timer;
  if (firstClick) {
    login.finish();
  }
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    timer = null;
    if (!firstClick) {
      // payLaunch();
    }
  }, 300);
};
const fetchCode = debounce(
  function () {
    if (state.countDown) clearInterval(state.countDown);
    state.count = 60;
    state.countDown = setInterval(() => {
      if (state.count < 1) {
        state.count = 0;
        // message.success('邮件已发送，请前往您设置的新邮箱查看验证码')
        clearInterval(state.countDown);
      } else {
        state.count--;
      }
    }, 1000);
    let obj = {};
    if (state.activeKey == 2) {
      obj["phone"] = state.formState.account;
    } else {
      obj["email"] = state.formState.account;
    }
    $http
      .post(state.activeKey == 2 ? "/sms/send_sms" : "/sms/send_email", obj)
      .then((res) => {
        //获取验证码 = 用于测试
        // $http.post('openapi/cache/list').then((res) => {
        //
        // })
      })
      .catch((error) => {
        // message.error(error.message || '发送失败，请重试！')
        if (state.countDown) clearInterval(state.countDown);
      });
  },
  1000,
  true
);
const {
  activeKey,
  checked,
  isRegister,
  activeMap,
  shareIs,
  isXy,
  rules,
  count,
  formState,
} = toRefs(state);
</script>
<style lang="scss" scoped>
.tabs-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 0 1rem;
}

.content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px 0;
  margin-top: 12px;

  .desc {
    color: #999999;
    font-size: 14px;
  }

  .loginbtn {
    width: 342px;
    height: 52px;
    background-image: linear-gradient(90deg, #fea975 0%, #ff6d49 100%);
    border: 0;
    font-size: 18px;
    border-radius: 32px;
  }

  .loginbtn:disabled {
    background: #d8d8d8 !important;
    color: #ffffff;
  }
}

.validate {
  width: 342px;
  height: 52px;
  display: flex;
  align-items: center;
  gap: 0 12px;

  .validatebtn {
    width: 95px;
    font-size: 12px;
    height: 40px;
    border-radius: 32px;
  }
}

.bitian::before {
  content: "*";
  color: red;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
