import { createApp } from "vue";
import App from "./App.vue";

//引入组件样式
import zhCn from "element-plus/es/locale/lang/zh-cn";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import lazyPlugin from "vue3-lazy";

//引入其他文件
import router from "./router";
import store from "./store";
import "@/assets/style/element.css";
import "@/assets/style/global.scss";
import "@/assets/style/element-variables.scss";
import datetime from "@/assets/date.js";
//播放器地址
// import "./aliplayer.css";

//请求封装
import svgIconRegistered from "@/utils/icon";
import http from "@/utils/request";
import "@/permission"; // 路由
import avator from '@/assets/image/avator.png'

const app = createApp(App);
svgIconRegistered(app);


window.errorAvatarImage = function (tar) {
  tar.src = avator
}

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  if (key == "Position") continue;
  app.component(key, component);
}

import adPosition from "@/position.vue";
app.component("Position", adPosition);

import WebHeader from "@/web/components/headers.vue";
import WebFooter from "@/web/components/footers.vue";
import WebVodList from "@/web/components/vodlist.vue";
app
  .component("WebHeader", WebHeader)
  .component("WebFooter", WebFooter)
  .component("WebVodList", WebVodList);

import H5Header from "@/h5/components/headers.vue";
import H5Footer from "@/h5/components/footers.vue";
import H5VodList from "@/h5/components/vodlist.vue";
import H5Page from "@/h5/components/page.vue";
import H5Back from "@/h5/components/back.vue";
// import '@/utils/service.js'
app
  .component("H5Header", H5Header)
  .component("H5Footer", H5Footer)
  .component("H5VodList", H5VodList)
  .component("H5Page", H5Page)
  .component("H5Back", H5Back);

function secondInterval() {
  window.requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  let recordClient = phoneClient()
  function secTimer() {
    if(phoneClient() != recordClient){
      location.reload()
      return
    }
    window.requestAnimFrame(secTimer);
    store.commit("SECOND", new Date().getSeconds());
    store.commit("MILLISECONDS", new Date().getMilliseconds());
  }
  secTimer();
}

function loadApiServer(obj) {
  obj = obj || {};
  let uri = {};
  if (location.protocol == "file:") {
    // 这种情况目前只在开发环境下发生，应该无需考虑
    let dfHost = "http://127.0.0.1:21001";
    let data = prompt("请输入API服务", dfHost);
    if (data) {
      obj = new URL(data);
    }
    uri = new URL(dfHost);
  } else {
    // 默认当前地址
    uri = new URL(location);
  }
  if (obj.protocol) uri.protocol = obj.protocol;
  if (obj.hostname) uri.hostname = obj.hostname;
  if (obj.port) uri.port = obj.port;

  let apiAddr = uri.origin;
  // if(typeof hmprefix == 'string') apiAddr +=
  //缓存当前请求地址
  store.commit("SETAPI", apiAddr);
  return apiAddr + "/openapi";
}

function loadSvg(filename) {
  return "/static/ico/" + filename;
}

// 初始化app api地址
async function initAppApi(obj) {
  let apiAddr = loadApiServer(obj);
  //缓存当前请求地址
  // store.commit("SETAPI", apiAddr)
  let ajaxHandle = http(apiAddr);
  app.config.globalProperties.$http = ajaxHandle;
  app.config.globalProperties.$Time = datetime;
  app.config.globalProperties.$Svg = loadSvg;

  // 启动服务
  secondInterval();
  await store.dispatch("getSitePub", ajaxHandle);
  // store.dispatch("insertSiteInfo", ajaxHandle);
  store.dispatch("getSiteClass", ajaxHandle);
  store.dispatch("getSiteTheme", ajaxHandle);
  store.dispatch("getSiteInfo", ajaxHandle);
  // store.dispatch("createTourists", ajaxHandle);

  // store.dispatch("getSiteInfo", ajaxHandle).then(res => {
  app
    .use(store)
    .use(router)
    .use(ElementPlus, {
      locale: zhCn,
    })
    .use(lazyPlugin, {
      loading: loadSvg("loading.svg"),
      error: loadSvg("loading.svg"),
    })
    .mount("#app");
  // })
}
initAppApi(
  process.env.NODE_ENV == "development"
    ? {
        protocol: "http",
        "hostname": "127.0.0.1",
        // hostname: "192.168.2.163",
        port: 8081,
      }
    : {}
);

window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}

export default app;
