<template>
  <template v-if="$route.name != 'Member'">
    <div class="section-banner">
      <!-- <Position position="banner" v-if="$route.name == 'Index'" /> -->
      <Position position="top" />
    </div>
    <div class="clear"></div>
    <!-- 浮动 -->
    <div>
      <Position position="float" />
    </div>
    <div class="clear"></div>
  </template>
  <header>
    <div class="layout">
      <div class="logo">
        <router-link :to="{ name: 'Index' }" style="display: flex; align-items: center">
          <!--          <img :src="`${store.getters.api + store.state.siteOtherInfo.logo}`" v-if="$store.state.siteOtherInfo.logo" />-->
          <img :src="$Svg('logo.png')" />
          <!-- <span>ABCDEFG.COM</span> -->
          <span>{{ store.state.siteInfo.SiteURL }}</span>
        </router-link>
      </div>
      <div class="input" @click="$router.push({ name: 'Search' })">
        <!-- <input placeholder="" type="text" /> -->
        <span class="search">
          <img :src="$Svg('search.svg')" />
        </span>
      </div>
      <div class="down" @click="$router.push({ name: 'Download' })" v-if="isIos() && store.state.iosUrl">
        <img :src="downloadApp.icon" />
        <p>下载 <br> APP</p>
      </div>
      <div class="down" v-if="isAndroid() && store.state.apkUrl">
        <a download :href="store.state.apkUrl">
          <img :src="downloadApp.icon" />
          <p>下载 <br> APP</p>
        </a>
      </div>
    </div>
    <slot></slot>
  </header>
  <div class="clear"></div>
  <div>
    <Position position="bottom" />
  </div>
  <template v-if="$route.name != 'Member'">
    <div class="clear"></div>
    <div>
      <Position position="nextdown" />
    </div>
    <div class="clear"></div>
    <div>
      <Position position="menudown" />
    </div>
    <div class="clear"></div>
    <div class="three">
      <Position position="topthree" />
    </div>
    <div class="clear"></div>
    <div>
      <Position position="mytop" v-if="$route.name != 'Index'" />
    </div>
    <div class="clear"></div>
  </template>
</template>
<script setup>
import {
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  nextTick,
  watch,
  computed,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isHasImg, isIos, isAndroid } from "@/utils/index";
import { getToken, isLoginAuth } from "@/utils/auth";
let router = useRouter();

//获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;
const store = useStore();

const state = reactive({
  showMenber: false,
  domain: "",
  userInfo: computed(() => store.getters.userInfo) || {},
});
const { showMenber, domain, userInfo } = toRefs(state);
const FN = {
  getHostName() {
    // if (store.state.siteInfo.Forever) {
    //   state.domain = store.state.siteInfo.Forever
    // } else if (store.state.siteInfo.SiteURL) {
    //   state.domain = store.state.siteInfo.SiteURL
    // } else {
    //   state.domain = location.hostname
    // }
    $http.get("/siteConfig/domain").then(({ data }) => {
      if (data != "") {
        store.commit("SETISITENAME", data);
      } else {
        store.commit("SETISITENAME", window.location.hostname);
      }
    });
  },
  toUser() {
    if (getToken()) {
      router.push({ name: "MemberHome" });
    } else {
      store.commit("SETLOGINPATH", "MemberHome");
      router.push({ name: "MemberLogin" });
    }
  },
};
const downloadApp = {
  icon: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjc4MTgyNDY3MzY4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjIzODk5IiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiPjxwYXRoIGQ9Ik04NzQuNjY2NjY3IDY0YzAtMzYuMjY2NjY3LTI3LjczMzMzMy02NC02NC02NEgyMTMuMzMzMzMzQzE3Ny4wNjY2NjcgMCAxNDkuMzMzMzMzIDI3LjczMzMzMyAxNDkuMzMzMzMzIDY0djg5NmMwIDM2LjI2NjY2NyAyNy43MzMzMzMgNjQgNjQgNjRoNTk3LjMzMzMzNGMzNi4yNjY2NjcgMCA2NC0yNy43MzMzMzMgNjQtNjRWNjR6TTU3MS43MzMzMzMgOTE3LjMzMzMzM2gtMTI4Yy0xMi44IDAtMjEuMzMzMzMzLTguNTMzMzMzLTIxLjMzMzMzMy0yMS4zMzMzMzNzOC41MzMzMzMtMjEuMzMzMzMzIDIxLjMzMzMzMy0yMS4zMzMzMzNoMTI4YzEyLjggMCAyMS4zMzMzMzMgOC41MzMzMzMgMjEuMzMzMzM0IDIxLjMzMzMzM3MtOC41MzMzMzMgMjEuMzMzMzMzLTIxLjMzMzMzNCAyMS4zMzMzMzN6TTgzMiA3NjhIMTkyVjU5LjczMzMzM2MwLTEyLjggNi40LTE3LjA2NjY2NyAxNy4wNjY2NjctMTcuMDY2NjY2aDU5Ny4zMzMzMzNjMTIuOCAwIDI1LjYgNC4yNjY2NjcgMjUuNiAxNy4wNjY2NjZWNzY4eiIgZmlsbD0iI2ZmZmZmZiIgcC1pZD0iMjM5MDAiPjwvcGF0aD48L3N2Zz4=",
  getDownloadUrl() {
    let u = navigator.userAgent;
    //ios终端
    if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      return "/static/ico/logo.png";
    } else {
      return "/static/ico/logo.png";
    }
  },
};
onMounted(() => {
  FN.getHostName();
  console.log(store.state.apkUrl, '====')
});

watch(
  () => router,
  (n, o) => {
    let route = n.currentRoute.value || {};
    switch (route.name) {
      case "CateList":
      case "Discovery":
      case "Topic":
      case "People":
        state.showMenber = false;
        break;
      default:
        state.showMenber = true;
    }
  },
  { immediate: true, deep: true }
);
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  // height: 115px;
  box-shadow: 0px 4px 20px 0px rgba(78, 78, 78, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  background-image: var(--theme);

  .layout {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 14px;
    // grid-template-columns: auto 130px 50px;
    grid-gap: 10px;
    padding-top: 16px;
    padding-bottom: 10px;

    .logo {
      display: flex;
      align-items: center;
      padding-left: 10px;
      position: relative;

      a {
        position: relative;
        width: 100%;
      }

      span {
        position: absolute;
        left: 38px;
        right: 0;
        bottom: 4px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .input {
      position: relative;
      width: 90%;
      height: 34px;
      background: #ffffff;
      border-radius: 20px;
      padding: 0 10px;
      color: #999;

      input {
        outline: none;
        width: 72%;
        height: 34px;
        border: 0;
        border-radius: 5px;
        display: none;
      }

      .search {
        position: absolute;
        top: 2px;
        right: 2px;
        height: 30px;
        width: 30px;
        background-image: var(--search);
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;

        img {
          margin-left: 8px;
        }
      }
    }

    .down {
      cursor: pointer;
      width: 60px;
      height: 48px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      padding-right: 5px;

      p {
        transform: scale(0.8);
        margin-top: -6px;
      }

      a {
        padding-right: 10px;
        // 	border:2px solid #fff;
        // 	display: inline-block;
        // 	width:34px;
        // 	height:34px;
        // 	border-radius: 50%;
      }

      div {
        font-size: 0;

        i {
          font-weight: bold;
          font-size: 16px;
        }
      }

      // span{
      // 	font-size: 10px;
      // 	display: block;
      // 	padding: 0;
      // 	margin:0;
      // 	line-height: auto;
      // }
      img {
        width: 26px;
      }
    }
  }
}
</style>
