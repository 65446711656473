<template>
  <template v-if="!$route.path.includes('/member') && !$route.path.includes('/agent')">
    <div>
      <Position position="generalCustom" />
    </div>
    <div>
      <Position position="foot" />
    </div>
    <div>
      <Position position="indexpic" />
    </div>
  </template>
  <footer>
    <div class="layout">
      <!-- 本站资源由 <a href="{{$store.state.siteInfo.TechnicalSupport}}" target="_blank" class="tec" style="color:red">{{$store.state.siteInfo.TechnicalSupport}}</a> 提供 -->
      <!-- <p>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
				<a href="">友情连接</a>
			</p>
			<div class="desc">本站内容均从互联网收集而来，仅供交流学习使用，版权归原创者所有如有侵犯了您的权益，尽请通知我们，本站将及时删除侵权内容。</div>
			<div class="copyright">Copyright @ 2022 XXXX 版权所有</div> -->

      <div>
        <Position position="indexfloat" v-if="$route.name == 'Index'" />
        <Position position="float" v-else />
      </div>
      <div class="clear"></div>
    </div>
    <ul class="tool" v-if="isWs">
      <li @click="myTimeupdate()" class="ser">
        <div class="svg" :class="{ quivered: newMesNum > 0 }">
          <img :src="$Svg('kefu.svg')" alt="客服" />
        </div>
        <p>客服</p>
        <span class="remind" v-if="newMesNum">{{ newMesNum }}</span>
      </li>
    </ul>
    <ul class="menu">
      <li :class="{ on: $route.name == 'Index' || $route.name == 'CateList' }">
        <router-link :to="{ name: 'Index' }">
          <div class="svg">
            <img :src="$Svg('home.svg')" />
          </div>
          <p>首页</p>
        </router-link>
      </li>
      <li :class="{ on: $route.name == 'Discovery' }">
        <router-link :to="{ name: 'Discovery' }">
          <div class="svg">
            <img :src="$Svg('faxian.svg')" />
          </div>
          <p>发现</p>
        </router-link>
      </li>
      <li :class="{ on: $route.name == 'Topic' }">
        <router-link :to="{ name: 'Topic' }">
          <div class="svg">
            <img :src="$Svg('theme.svg')" />
          </div>
          <p>专题</p>
        </router-link>
      </li>
      <li
        :class="{
          on:
            $route.name == 'People' ||
            $route.name == 'Star' ||
            $route.name == 'StarInfo',
        }"
      >
        <router-link :to="{ name: 'People' }">
          <div class="svg">
            <img :src="$Svg('star.svg')" />
          </div>
          <p>明星</p>
        </router-link>
      </li>
      <!-- <li :class="{ 'on': $route.name == 'Member' }">
        <router-link :to="{name:'Member'}">
        <div @click="FN.toUser()" class="svg">
          <img :src="$Svg('me.svg')" />
        </div>
        <p>我的</p>
        </router-link>
      </li> -->

      <li :class="{ on: route.path.includes('member') }">
        <div @click="FN.toUser()" class="svg">
          <img :src="$Svg('me.svg')" />
        </div>
        <p>我的</p>
      </li>
    </ul>
  </footer>
  <template v-if="$route.name != 'Member'">
    <div style="display: none" v-if="$route.name != 'Index'">
      <Position position="count" />
    </div>
    <div style="display: none" v-else>
      <Position position="indexcount" />
    </div>
  </template>
  <div id="syncCustomer" style="display: none"></div>
</template>
<script setup>
import {
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
  nextTick,
  watch,
  computed,
} from "vue";
import { isLoginAuth, isTourists, getToken } from "@/utils/auth";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { storage, debounce, throttle } from "@/utils/index";
const store = useStore();
const router = useRouter();
const route = useRoute();

// 获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

const state = reactive({
  isMessage: true,
  isWs: false,
  newMesNum: 0,
});
// watch(() => store.getters.isWs, val => state.isWs = val)
const { isMessage, isWs, newMesNum } = toRefs(state);
const FN = {
  // 回到顶部
  toTop() {
    $("body,html").animate({ scrollTop: 0 }, 480);
  },
  toUser() {
    if (getToken()) {
      router.push({ name: "MemberHome" });
    } else {
      store.commit("SETLOGINPATH", "MemberHome");
      router.push({ name: "MemberLogin" });
    }
  },

  login() {
    store.commit("SETLOGINPATH", "/ws");
    store.commit("SETISLOGIN", true);
  },
  fetchCustomer() {
    // let tags = document.getElementById('blzxMinChatWindowDiv')
    // if (tags === null) {
    storage.setSession("wsApi", false);
    $http
      .get("/template/customer", {})
      .then((res) => {
        // console.log(res);
        let data = res.data || {};
        if (data?.js) {
          state.isWs = true;
          storage.setSession("wsApi", data.api);
          nextTick(() => {
            document.querySelector("#syncCustomer").innerHTML = data.js;
            Array.prototype.forEach.call(
              document.querySelector("#syncCustomer").children,
              (ele) => {
                // 标签复制
                const tag = document.createElement(ele.tagName.toLowerCase());
                // 内容复制
                tag.innerHTML = ele.innerHTML;
                // 属性复制
                Array.prototype.forEach.call(ele.attributes, (attr) => {
                  tag.setAttribute(attr.name, attr.value);
                });
                document.body.appendChild(tag);
              }
            );
            if (getToken()) {
              store.dispatch("refreshInfo");
              // console.log(store.getters.userInfo);
            }
            // setTimeout(() => {
            //   let userInfo = storage.getSession("tpl01UserInfo") || {};
            //   let isWsModel = storage.getSession("isWsModel")
            //   if (isWsModel) return false
            //   if (getToken() && userInfo?.isTourists != 1) {
            //     serCtr.open(store.getters.userInfo.id, data.api)
            //     storage.setSession("isWsModel", true)
            //   }
            // }, 500)
          });
        }
      })
      .catch(() => {
        state.isWs = false;
        if (getToken()) {
          store.dispatch("refreshInfo");
          console.log(store.getters.userInfo);
        }
      });
    // }else{
    //   document.getElementById('blzxMinChatWindowDiv').style.display = 'block'
    // }
  },

  // 客服系统
  toCustomer() {
    let userInfo = storage.getSession("tpl01UserInfo") || {};
    // console.log(userInfo)
    if (!(getToken())) {
      store.commit("SETLOGINPATH", "MemberHome");
      router.push({ name: "MemberLogin" });
      return false;
    } else {
      if (userInfo?.id) {
        // storage.getSession("wsApi")
        serCtr.open(store.getters.userInfo.id);
      } else {
        store.dispatch("refreshInfo");
        serCtr.open(store.getters.userInfo.id);
      }
      setSerEvent();
    }
  },
};
//防抖处理
const myTimeupdate = debounce(FN.toCustomer, 500, true);
onMounted(() => {
  FN.fetchCustomer();
  setSerEvent();
});
function setSerEvent() {
  if (window.serCtr) {
    serCtr.events.message ||= function (mes, num, display) {
      if (display === "none") {
        newMesNum.value = num;
      } else {
        newMesNum.value = 0;
      }
    };
    serCtr.events.opened ||= function () {
      newMesNum.value = 0;
    };
    serCtr.events.narrow ||= function () {
      newMesNum.value = 0;
    };
  }
}
</script>
<style lang="scss" scoped>
footer {
  text-align: center;
  margin-top: 30px;

  .layout {
    // background: rgba(231, 231, 231, 0.6);
    display: block;
    padding-top: 20px;
    padding-bottom: 60px;
    font-size: 12px;
    color: #ccc;

    p {
      padding: 20px 5%;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-gap: 10px;

      a {
        color: #999;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    }

    .desc {
      font-size: 12px;
      color: #999999;
      letter-spacing: 1px;
      font-weight: 400;
      padding-top: 20px;
      margin-bottom: 10px;
    }

    .copyright {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #999999;
      letter-spacing: 1px;
      font-weight: 400;
      margin-bottom: 50px;
    }
  }

  .menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 10px;
    // height: 50px;
    background-image: var(--theme);
    padding: 10px 0 12px 0;
    // padding-bottom: constant(safe-area-inset-bottom); 
    // padding-bottom: env(safe-area-inset-bottom);

    li {
      text-align: center;
      position: relative;
      opacity: 0.7;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: -10px;
        width: 0;
        height: 0;
        margin-left: -8px;
      }

      &.on {
        opacity: 1;

        &:before {
          border: 8px solid;
          border-top-width: 5px;
          border-color: #fff transparent transparent transparent;
        }

        // font-weight: 600;
        // p{
        // 	color: #000;
        // }
        // div{
        // 	img:nth-child(1){display: none;}
        // 	img:nth-child(2){display: inline-block;}
        // }
      }

      p {
        width: 100%;
        color: #fff;
        font-size: 12px;
        margin: 0;
      }

      // div{
      // 	font-size: 0;
      // 	img{
      // 		width:22px;
      // 		&:nth-child(2){
      // 			display: none;
      // 		}
      // 	}
      // }
    }
  }

  .tool {
    position: fixed;
    right: 0;
    bottom: 10vh;
    background: #fff;
    width: 50px;
    border: 1px solid #eee;
    z-index: 30;
    transition: all 0.2s;
    border-radius: 3px 0 0 3px;
    overflow: hidden;
    &:hover {
      width: 60px;
    }
    .quivered {
      animation: 0.4s linear 0.4s infinite alternate blink;
    }
    .remind {
      background-color: #f25a5a;
      border-radius: 8px;
      text-align: center;
      color: white;
      padding: 0 4px;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 14px;
    }
    li {
      position: relative;
      padding: 5px 0;
      cursor: pointer;
      &.ser {
        position: relative;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        border-bottom: 1px solid #eee;
      }

      &:hover {
        background-image: var(--theme);

        p {
          color: #fff;
        }

        img {
          filter: drop-shadow(-25px 0px 0px #fff);
          -webkit-filter: drop-shadow(-25px 0px 0px #fff);

          &.roate {
            filter: drop-shadow(-25px 0px 0px #fff);
            -webkit-filter: drop-shadow(-25px 0px 0px #fff);
          }
        }
      }

      img {
        filter: drop-shadow(-25px 0px 0px var(--svg));
        -webkit-filter: drop-shadow(-25px 0px 0px var(--svg));

        &.roate {
          width: 32px;
          height: 24px;
          filter: drop-shadow(-25px 0px 0px var(--svg));
          -webkit-filter: drop-shadow(-25px 0px 0px var(--svg));
        }
      }

      p {
        margin-top: 3px;
        color: var(--svg);
        font-size: 11px;
      }
    }
  }
}
</style>
