
// import type { App } from 'vue'
import SvgIcon from '@/components/SvgIcon/index.vue' // svg组件
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext)
const req = require.context('@/assets/icons', false, /\.svg$/)
requireAll(req)

export default function svgIconRegistered(app) {
  app.component('SvgIcon', SvgIcon)
}
