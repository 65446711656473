<template>
  <el-dialog
    v-model="visible"
    :width="420"
    top="10%"
    :close-on-click-modal="false"
    :show-close="false"
    class="login"
    @close="cancelFn"
  >
    <template #header>
      <div style="display: flex; justify-content: flex-end"></div>
      <svg-icon name="quxiao" color="#999" size="1rem" @click="cancelFn" />
    </template>
    <base-login v-if="visible" />
  </el-dialog>
</template>

<script setup>
import baseLogin from "./bases.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
watch(
  () => store.getters.isLogin,
  (val) => {
    //
    //
    visible.value = val;
  }
);
const visible = ref(false);
const cancelFn = () => {
  // emits('update:visible', false)
  visible.value = false;
  store.commit("SETISLOGIN", false);
};
</script>
<style lang="scss" scoped></style>
