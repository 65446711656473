<template>
  <svg aria-hidden="true" :class="svgClass" :style="`width:${size};height:${size};color:${color}`">
    <use :xlink:href="symbolId" :fill="props.color" />
  </svg>
</template>

<script  setup>
import { computed } from 'vue'
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '1em'
  },
  className: { type: String },
})
const svgClass = computed(() => props.className ? "svg-icon " + props.className : "svg-icon");
const symbolId = computed(() => `#icon-${props.name}`)
</script>
<style scoped lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  outline: none;

  g {
    fill: red;
  }
}
</style>