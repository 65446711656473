<template>
  <el-dialog v-model="visible" :destroyOnClose="true" :close-on-click-modal="false" :width="400" class="payloada"
    show-close @close="cancelFn">
    <el-skeleton :rows="5" v-if="!isCurrPay?.price" />
    <div class="paybox" v-show="!myIsPay" v-else>
      <div class="paybox-title">
        <span class="y">¥</span>
        <span class="num">{{ isCurrPay.price / 100 }}</span>
        <div class="jinge">订单金额</div>
      </div>
      <div class="paybox-content">
        <div class="paybox-content-item">
          <div class="paybox-content-item">支付方式</div>
          <div class="paybox-content-item-list">
            <ul>
              <template v-for="(item, index) in payoption" :key="item">
                <li v-if="item">
                  <div>
                    <img :src="item.icon" alt="" />
                    <div>{{ item?.name }}</div>
                  </div>
                  <input type="radio" class="inp" name="payrad" :value="index" v-model="payraddata"
                    :checked="index == payraddata" />
                </li>
              </template>
            </ul>
          </div>
        </div>

        <el-button type="primary" class="gobtn" @click="trigger">立即支付</el-button>
      </div>
    </div>

    <div v-show="myIsPay" class="pay-content">
      <div class="pay-code-box">
        <div class="code-box">
          <div class="code" ref="topRef" v-loading="loading"
            :style="{ 'padding': typeIndex == 2 ? '14px 0 0 14px' : '' }">
            <img ref="codeRef">
            <!-- <img v-if="typeIndex == 3" :src="codeImg" /> -->
            <!-- <iframe :src="iframeUrl" v-if="typeIndex == 2" width="175" height="175" frameborder="0"></iframe> -->
          </div>
          <div class="paydesc">
            <div class="paydesc-title">此订单在30分钟内支付有效</div>
            <div style="font-size: 14px; text-align: center; line-height: 50px">
              支付金额：<label style="color: #fc987e; font-size: 24px">{{
                isCurrPay.price / 100
              }}</label>元
            </div>
            <div v-if="payoption[typeIndex]?.payType == 'custom'">
              请扫描二维码完成支付
            </div>
            <div v-else>
              请使用{{ payoption[typeIndex]?.name }}扫一扫，轻松支付
            </div>
            <!-- <div style="color: #999999">
              开通前请仔细阅读
              <span style="color: #fc987e">《VIP会员服务协议》</span>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="paybtns">
        <a-button type="primary" @click="pay.cancel">取消订单</a-button>
      </div> -->
    </div>
  </el-dialog>
</template>
<script setup>
import { ElMessage as message } from "element-plus";
import {
  reactive,
  toRefs,
  ref,
  watch,
  getCurrentInstance,
  computed,
  onUnmounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { isTourists } from "@/utils/auth";
import { useRouter } from "vue-router";
//引入js
const insertPayTags = () => {
  let payTags = document.getElementById("payTags");
  if (payTags === null) {
    payTags = document.createElement("script");
    payTags.src = "/static/js/qrious.js";
    payTags.id = "payTags";
    let head = document.getElementsByTagName("head")[0];
    head.appendChild(payTags);
  }
};
//获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;
const store = useStore();
// console.log(store.getters.isPayIng, "22222222222222");
watch(
  () => store.getters.isPayIng,
  (val) => {
    insertPayTags();
    if (val) {
      fetchPck();
      fetchIsPay(val);
    } else {
      clearInterval(state.timer);
      visible.value = false;
    }
  }
);
const codeRef = ref(null);
const topRef = ref(null);
const payraddata = ref(0);
//发起订单
const payLaunch = () => {
  if (isTourists("click")) {
    cancelFn();
    return false;
  }
  state.typeIndex = payraddata.value;
  state.loading = true;
  state.isCurrPay = state.pckList.filter((v) => v.id == state.activeKey)[0] || {
    price: Number(store.getters.rechargePrice) * 100,
  };
  let obj = {
    // rechargeKind: store.getters.payType == "gold" ? 2 : 1,
    // rechargeType: state.activeKey == 0 ? 2 : 1,
    rechargeType: store.getters.payType == "gold" ? 2 : 1,
    rechargeKind: state.activeKey == 0 ? 2 : 1,
  };
  obj["packageId"] = state.isCurrPay?.id || 0;
  if (state.activeKey == 0) obj["rechargePrice"] = state.isCurrPay.price;
  payoption.value[payraddata.value].addr = payoption.value[payraddata.value].addr.replace('/openapi', '')
  $http
    .post(
      payoption.value[payraddata.value]?.addr,
      obj,
    )
    .then((res) => {
      state.myIsPay = true;
      let { data } = res;
      if (payoption.value[payraddata.value].payType == "wechat") {
        nextTick(() => {
          //@ts-ignore
          new QRious({
            element: codeRef.value,
            value: data.url,
          });
          state.loading = false;
        });
      } else if (payoption.value[payraddata.value].payType == 'alipay') {
        var iframe = document.createElement("iframe");
        iframe.src = data.url;
        iframe.width = "175px";
        iframe.height = "175px";
        topRef.value.appendChild(iframe);
        iframe.style.border = "0";
        iframe.onload = () => {
          state.loading = false;
        };
        // state.iframeUrl = data.url
      } else {
        nextTick(() => {
          //@ts-ignore
          new QRious({
            element: codeRef.value,
            value: data.url,
          });
          state.loading = false;
        });
      }
      state.orderId = data.orderId;
    })
    .catch((error) => {
      message.error(error.response?.data.message || "发起支付失败！");
      state.loading = true;
      state.myIsPay = false;
    })
};
let timer = null;
const trigger = () => {
  let firstClick = !timer;
  if (firstClick) {
    payLaunch();
  }
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    timer = null;
    if (!firstClick) {
      // payLaunch();
    }
  }, 1000);
};
let payoption = ref([]);
const fetchIsPay = async (val) => {
  await $http.get("/siteConfig/pay").then((res) => {
    let { data } = res;
    payoption.value = data.paySupport
    console.log("payoption", payoption.value);
    state.isPayObj = data;
    state.visible = val;
    if (data.paySupport.length == 0) {
      cancelFn();
      message.error("暂无可用支付方式！");
    }
  });
};
const fetchPck = async () => {
  await $http
    .get("/pck/all", { params: { type: state.type == "gold" ? 2 : 1 } })
    .then((res) => {
      let data = res.data
        ? res.data.sort((a, b) => a.sort - b.sort)
        : [];
      state.pckList = data;
      if (store.getters.rechargePrice) {
        state.activeKey = 0;
      } else {

        state.activeKey = store.state.payIndex || data[0].id;
      }
      state.rechargePrice = store.getters.rechargePrice;
      console.log("rechargePrice：", state.rechargePrice);

      state.isCurrPay = state.pckList.filter(
        (v) => v.id == state.activeKey
      )[0] || { price: state.rechargePrice * 100 };
    });
  // if (state.type == "gold") {
  //   $http.get("/openapi/siteConfig/goldRate").then((res: any) => {
  //     let { data } = res;
  //     state.payRate = data;
  //     state.rechargeGold = data.gold;
  //     state.rechargePrice = data.price;
  //     console.log("兑换比例", data);
  //   });
  // }
};
const state = reactive({
  activeKey: 0,
  pckList: [],
  visible: false,
  type: computed(() => store.getters.payType) || "gold",
  userInfo: computed(() => store.getters.userInfo) || {},
  isCurrPay: {},
  orderId: "",
  timer: null,
  typeIndex: 0,
  iframeUrl: "",
  myIsPay: false,
  myCustomPrice: 0,
  payRate: {},
  rechargePrice: 0,
  rechargeGold: 0,
  loading: false,
  isPayObj: {},
  codeImg: "",
});
watch(
  () => state.orderId,
  (val) => {
    if (val) {
      state.timer = setInterval(() => {
        if (state.loading) return false;
        $http
          .post("/pay/order/status", { orderId: state.orderId })
          .then((res) => {
            let { data } = res;
            if (data) {
              message.success("支付成功！");
              store.dispatch("refreshInfo");
              cancelFn();
            }
          });
      }, 1500);
    }
  }
);
onUnmounted(() => {
  clearInterval(state.timer);
});
const { visible, isCurrPay, typeIndex, myIsPay, codeImg, loading } = toRefs(state);
const emits = defineEmits(["update:visible"]);
const cancelFn = () => {
  emits("update:visible", false);
  //关闭支付弹窗
  store.commit("SETISPAYING", false);
  store.commit("SETRECHAR", 0); //重置自定义金额
  payraddata.value = 0; //充值方式重置
  state.isCurrPay = {};
  state.activeKey = 0;
  state.loading = false;
  state.myIsPay = false;
  //套餐序号
  store.commit("SETPAYINDEX", 0);
  clearInterval(state.timer);
};
// console.log(state.isPayObj, "state.isPayObj");

//监听路由变化
const router = useRouter();
watch(
  () => router.currentRoute.value.path,
  (val) => {
    cancelFn();
  }
);
</script>

<style lang="scss">
.payload {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    top: 26px;
    right: 10px;
    color: #fff;
  }
}
</style>
<style lang="scss" scoped>
.gobtn {
  width: 200px;
  margin: 30px auto 3px auto;
  background-image: var(--btnbg4);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
}

:deep(.payloada .ant-modal-close) {
  background: #939393;
}

.paybox {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0 2rem;

  .paybox-title {
    font-family: PingFangSC-Medium;
    color: #333333;
    letter-spacing: 1px;
    // line-height: 40px;
    font-weight: 500;
    text-align: center;

    .num {
      font-size: 28px;
    }

    .y {
      font-size: 18px;
    }

    .jinge {
      font-size: 12px;
      color: var(--fontcolor-m);
    }
  }

  .paybox-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0 2rem;

    .paybox-content-title {
      display: flex;
      margin-top: 30px;

      .huiyuan {
        //平行四边形背景
        background-image: linear-gradient(to top, #fbc2eb 0%, #fc987e 100%);
        width: 90px;
        height: 30px;
        text-align: center;
        transform: skewX(-20deg);
        border-radius: 5px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
        font-weight: 500;
      }

      .huiyuanquanyi {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #333333;
        font-weight: 500;
        margin-left: 10px;
      }
    }

    .paybox-content-header {
      display: flex;
      align-items: center;
      height: 40px;

      .paybox-content-item-header-title {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }

      .paybox-content-item-header-more {
        width: 12px;
        height: 12px;
        background: #000000;
        border-radius: 50%;
      }
    }

    .paybox-content-item {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #666;
      font-weight: 400;
      margin-top: 10px;
    }

    .paybox-content-item-list {
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding-left: 0px;

        li {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 20px;

          input {
            width: 18px;
          }

          div {
            display: flex;

            img {
              width: 24px;
            }

            div {
              margin-left: 8px;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: var(--fontcolor-m);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.pay-content {
  max-height: 500px;
  min-height: 337px;

  .pay-code-box {
    .code-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .code {
        height: 190px;
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid #e0e0e0;
      }

      .paydesc {
        text-align: center;

        div {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .paydesc-title {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #999999;
          letter-spacing: 0;
          font-weight: 400;
          text-align: center;
        }
      }

      img {
        width: 180px;
        height: fit-content;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
</style>
