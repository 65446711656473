<template>
	<div class="page" v-if="total > 1">
		<ul>
			<li>共 <span>{{total}}</span> 项</li>
			<li @click="$emit('fn',pg.first)"><a></a></li>
			<li @click="$emit('fn',pg.prev)"><a></a></li>
			<template v-for="(vo,idx) in pg.list" :key="idx">
				<li class="on" v-if="vo == page"> <strong>{{page}}</strong> </li>
				<li :class="{'show':pg.show.indexOf(vo) != -1}" @click="$emit('fn',vo)" v-else> <a>{{vo}}</a> </li>
			</template>
			<li @click="$emit('fn',pg.next)"><a></a></li>
			<li @click="$emit('fn',pg.last)"><a></a></li>
		</ul>
	</div>
</template>
<script setup>
	import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	const props = defineProps({
		total: { default: 0 },
		page: { default: 1 },
		limit: { default: 0 },
	})
	const state = reactive({
		pg:{
			first:0,
			prev:0,
			next:0,
			last:0,
			list:[],
			show:[],
		},
	})
	const { pg } = toRefs(state)
	const FN = {
		pg(){
			let pages  = Number(props.page || 1)
			let start = pages - 2;
			let end   = pages + 2;
			// let max = Math.floor(props.total / props.limit)
      let max = Math.ceil(props.total / props.limit) // 向上取整数

			if(end < 5){
				end = 5
			}
			if(max < 5){
				end = max
			}else if(end > max) end = max;

			if(start > (max - 5)){
				start = max - 5
			}

			if(start < 1) start = 1;

			let res = []
			for(let i=start;i<=end;i++){
				res.push(i)
			}
			if(res.length == 0){
				res = [1]
			}


			let resp = {
				first:1,
				prev:(pages - 1) > 0 ? (pages - 1):1,
				list:res,
				next:(pages + 1) <= max ? (pages + 1):max,
				last:max,
				show:[],
			}
			let show = []
			if(pages == 1){
				show = [1,2,3]
			}else if(pages == max){
				show = [max-2,max-1,max]
			}else{
				show = [resp.prev,pages,resp.next]
			}

			resp.show = show
			state.pg = resp
			// return resp
		}
	}
	onMounted(() => {
		nextTick(()=>{
			FN.pg()
		})
	})

</script>
<style lang='css' scoped>
.page{position: relative;width: 100%;}
.page ul {padding: 20px 0;text-align: center;}
.page ul li {display: inline-block;padding: 0 10px;border: 1px solid var(--svg);text-align: center;margin-right: 10px;border-radius: 6px;line-height:32px;height: 32px;min-width: 32px;}
.page ul li {cursor: pointer;}
.page ul li:after {color:var(--svg);}
.page ul li:first-child {cursor: default;}
.page ul li:last-child {margin-right: 0;}
.page ul li strong {color: inherit;font-weight: normal;}
.page ul li:last-child:after{content:'尾页';}
.page ul li:nth-child(2):after{content:'首页';}
.page ul li:nth-child(3):after{content:'上一页';}
.page ul li:nth-last-child(2):after{content:'下一页';}
@media screen and (max-width: 760px){
    .page ul li{display: none;}
    .page ul li.on{display: inline-block;background-image:var(--search);color: #fff;font-weight: 600;}
    .page ul li.show{display: inline-block;}
    .page ul li.show a{color:var(--svg);}
    .page ul li:last-child{display: inline-block;}
    .page ul li:nth-child(2){display: inline-block;}
    .page ul li:nth-child(3){display: inline-block;}
    .page ul li:nth-last-child(2){display: inline-block;}

    .page ul li:last-child:after{content:'»';}
    .page ul li:nth-child(2):after{content:'«';}
    .page ul li:nth-child(3):after{content:'‹';}
    .page ul li:nth-last-child(2):after{content:'›';}
}
</style>