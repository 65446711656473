<template>
  <el-dialog v-model="visible" :width="938" class="payModel" @close="cancelFn" :close-on-click-modal="false"
    :show-close="false">
    <template #header>
      <div class="pay-bg">
        <svg-icon name="quxiao" @click="cancelFn" class="quxiaoIcon" color="#fff" size="18px" />
        <img v-if="userInfo?.headImg" :src="userInfo.headImg" />
        <div v-else class="icons">
          <svg-icon name="zhanghao" size="32px" color="#fff" />
        </div>
        <div class="userInfo">
          <span style="font-size: 18px">{{
            userInfo.nickname || userInfo.name
          }}</span>
          <span v-if="type == 'gold'">金币余额：{{ userInfo.goldCoin }}</span>
          <span v-if="type == 'vip'">
            <span v-if="userInfo.expireTime &&
              userInfo.expireTime * 1000 > new Date().valueOf()
              ">VIP会员将在{{
    parseTime(userInfo.expireTime * 1000, "{y}-{m}-{d}")
  }}到期，购买后将顺延</span>
            <span v-else>开通VIP看大片</span>
          </span>
        </div>
      </div>
    </template>
    <div v-if="!myIsPay" class="pay-content">
      <template v-if="type == 'gold'">
        <h3 v-if="pckList.length > 0 || state.payRate.switch">
          请选择购买金额
        </h3>
        <div class="pay-box">
          <div v-for="item in pckList" @click="pay.checkPay(item.id)"
            :class="{ 'item-pay-box': true, active: item.id == activeKey }">
            <div class="gold-number">{{ item.number }}金币</div>
            <div class="amount">¥&nbsp;{{ item.price / 100 }}</div>
          </div>
          <div style="width: 426px" v-if="state.payRate.switch" @click="pay.checkPay(0)"
            :class="{ 'item-pay-box': true, active: 0 == activeKey }">
            <div v-if="0 != activeKey">
              <div class="gold-numbers">自定义金额</div>
            </div>
            <div v-else>
              <div class="gold-number">{{ rechargeGold }}金币</div>
              <input class="amount" @input="pay.changePrice" v-model="rechargePrice" placeholder="¥0.00" type="text"
                ref="inputRef" />
            </div>
          </div>
        </div>
        <div style="padding: 0px 0 60px" v-if="pckList.length == 0 && !state.payRate.switch" class="query-empty">
          <img src="@/assets/image/empty.png" alt="空状态" />
          <p>暂无金币套餐</p>
        </div>
      </template>
      <template v-if="type == 'vip'">
        <h3 v-if="pckList.length > 0">VIP套餐</h3>
        <div class="pay-box">
          <div v-for="item in pckList" style="height: 140px" @click="pay.checkPay(item.id)"
            :class="{ 'item-pay-box': true, active: item.id == activeKey }">
            <div class="gold-number">
              <p>{{ item.name }}</p>
              <p class="treat">VIP尊享特权 {{ item.number }}天</p>
            </div>
            <div class="amount">¥&nbsp;{{ item.price / 100 }}</div>
          </div>
        </div>
        <div style="padding: 0px 0 60px" v-if="pckList.length == 0" class="query-empty">
          <img src="@/assets/image/empty.png" alt="空状态" />
          <p>暂无VIP套餐</p>
        </div>
      </template>
      <el-button v-if="pckList.length != 0 || (state.payRate.switch && type == 'gold')" type="primary" class="gobtn"
        @click="goPaying()">去支付</el-button>
    </div>
  </el-dialog>
</template>
<script setup>
import { ElMessage as message } from "element-plus";
import {
  reactive,
  toRefs,
  ref,
  watch,
  getCurrentInstance,
  computed,
  onUnmounted,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { isTourists } from "@/utils/auth";
import { useRoute, useRouter } from "vue-router";
import { parseTime } from "@/utils/index";
//引入js
const insertPayTags = () => {
  let payTags = document.getElementById("payTags");
  if (payTags === null) {
    payTags = document.createElement("script");
    payTags.src = "/static/js/qrious.js";
    payTags.id = "payTags";
    let head = document.getElementsByTagName("head")[0];
    head.appendChild(payTags);
  }
};
//获取上下文
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;
const store = useStore();
const inputRef = ref(null);
const pay = {
  changePrice() {
    // state.rechargePrice = Math.ceil(state.rechargePrice)
    state.rechargePrice = state.rechargePrice.replace(/[^\d]/g, "");
    let rate = state.payRate.gold / state.payRate.price;
    state.rechargeGold = state.rechargePrice * rate;
    state.rechargePrice = rechargePrice.value;
  },
  cancel() {
    $http
      .post(
        state.typeIndex == 1
          ? "/pay/wechat/close_order"
          : "/pay/ant/close_order",
        {
          orderId: state.orderId,
        }
      )
      .then((res) => {
        state.isCurrPay = {};
        state.myIsPay = false;
        clearInterval(state.timer);
      })
      .catch(() => {
        message.error("取消订单失败！");
      });
  },
  checkPay(val) {
    // state.myCustom = val == 0 ? true : false
    console.log(val, "val");
    state.activeKey = val;
    if (val == 0) {
      setTimeout(() => {
        inputRef.value.focus();
      }, 100);
      // inputRef.value.focus();
    } else {
      rechargePrice.value = "";
      rechargeGold.value = 0;
    }
    state.isCurrPay = state.pckList.filter(
      (v) => v.id == state.activeKey
    )[0] || { price: state.rechargePrice * 100 };
  },
};
watch(
  () => store.getters.isPay,
  (val) => {
    //
    insertPayTags();
    state.visible = val;
    if (val) {
      fetchPck();
      fetchIsPay();
    } else {
      clearInterval(state.timer);
    }
  }
);
const codeRef = ref(null);
const topRef = ref(null);
// const payThree = () => {
//   state.isCurrPay = state.pckList.filter((v) => v.id == state.activeKey)[0] || { price: state.rechargePrice * 100 }
//   let obj = {
//     rechargeKind: store.getters.payType == 'gold' ? 2 : 1,
//     rechargeType: state.activeKey == 0 ? 2 : 1
//   }
//   obj['packageId'] = state.isCurrPay?.id || 0
//   if (state.activeKey == 0) obj['rechargePrice'] = state.rechargePrice * 100
//   $http.post('/pay/third_party/page_pay', obj).then((res) => {
//     let { data } = res
//     message.success(data || '成功返回！')
//
//   }).catch((error) => {
//     message.error(error.response.data.message || '发起支付失败！')
//   })
// }
const fetchIsPay = () => {
  $http.get("/siteConfig/pay").then((res) => {
    let { data } = res;
    state.isPayObj = data;
  });
};
const fetchPck = () => {
  $http
    .get("/pck/all", { params: { type: state.type == "gold" ? 2 : 1 } })
    .then((res) => {
      // let { data } = res
      let data = res.data ? res.data.sort((a, b) => a.sort - b.sort) : [];
      state.pckList = data;
      // || data[0]?.id
      state.activeKey = store.state.payIndex || data ? data[0]?.id : 0;
    });
  if (state.type == "gold") {
    $http
      .get("/siteConfig/goldRate")
      .then((res) => {
        let { data } = res;
        state.payRate = data;
        // state.rechargeGold = data.gold;
        // state.rechargePrice = data.price;
      })
      .catch(() => {
        state.payRate = {};
      });
  }
};
const state = reactive({
  activeKey: 0,
  pckList: [],
  visible: false,
  type: computed(() => store.getters.payType) || "gold",
  userInfo: computed(() => store.getters.userInfo) || {},
  isCurrPay: {},
  orderId: "",
  timer: null,
  typeIndex: 0,
  iframeUrl: "",
  myIsPay: false,
  myCustomPrice: 0,
  payRate: {},
  rechargePrice: "",
  rechargeGold: 0,
  loading: false,
  isPayObj: {},
  codeImg: "",
});
watch(
  () => state.orderId,
  (val) => {
    if (val) {
      state.timer = setInterval(() => {
        if (state.loading) return false;
        $http
          .post("/pay/order/status", { orderId: state.orderId })
          .then((res) => {
            let { data } = res;
            if (data) {
              message.success("支付成功！");
              store.dispatch("refreshInfo");
              cancelFn();
            }
          });
      }, 1500);
    }
  }
);
onUnmounted(() => {
  clearInterval(state.timer);
});
const {
  activeKey,
  pckList,
  visible,
  type,
  isCurrPay,
  isPayObj,
  payRate,
  codeImg,
  typeIndex,
  rechargePrice,
  rechargeGold,
  myIsPay,
  userInfo,
} = toRefs(state);
const emits = defineEmits(["update:visible"]);
const cancelFn = () => {
  rechargePrice.value = "";
  rechargeGold.value = 0;
  emits("update:visible", false);
  //关闭支付弹窗
  store.commit("SETISPAY", false);
  state.isCurrPay = {};
  state.activeKey = 0;
  state.myIsPay = false;
  //套餐序号
  // store.commit("SETPAYINDEX", 0);
  clearInterval(state.timer);
};
const goPaying = () => {
  if (state.type == "gold" && (state.activeKey == 0 || state.activeKey == undefined)) {
    if (rechargePrice.value <= 0 || rechargePrice.value == '') {
      message.error("自定义金额不能为空！");
      return false;
    }
  }
  if (rechargePrice.value && state.activeKey == 0) {
    store.commit("SETRECHAR", rechargePrice.value); //设置充值金额
  }
  console.log("去支付", state?.isCurrPay.id);
  store.commit("SETPAYINDEX", state?.isCurrPay?.id); //设置支付的订单id
  store.commit("SETISPAYING", true); //设置支付弹窗显示
  store.commit("SETISPAY", false); //设置支付弹窗显示
  rechargePrice.value = "";
  rechargeGold.value = 0;
};

//监听路由变化
const router = useRouter();
watch(
  () => router.currentRoute.value.path,
  (val) => {
    cancelFn();
  }
);
</script>

<style lang="scss" scoped>
.pay-content {
  padding: 2rem;
  width: 100%;

  .paybtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 16px;
  }

  .pay-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 24px;
    margin: 1.5rem 0;

    input {
      border: 0;
      outline: none;
      text-align: center;
      background: transparent;
    }
  }

  .item-pay-box {
    width: 200px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid var(--payboxbd);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 12px 0;
    cursor: pointer;

    .gold-number {
      width: 100%;
      color: var(--fontcolor5);
      text-align: center;
      font-weight: 500;
      padding-bottom: 12px;
      border-bottom: 1px solid #e0e0e0;

      .treat {
        font-size: 12px;
        margin-top: 6px;
      }
    }

    &.active {
      border: 1px solid var(--payboxactivebd);
      background: #fff4ed;
    }
  }
}

.pay-code-box {
  width: 100%;
  height: 202px;
  border: 1px solid var(--payboxbd);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  margin-bottom: 1rem;

  .code-box {
    width: 100%;
    height: 200px;
    padding: 1rem 2rem;
    display: flex;
    gap: 0 2rem;

    .code {
      width: 170px;
      height: 170px;
      border: 1px solid var(--payboxbd);
      overflow: hidden;
      padding: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .paydesc {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      // line-height: 2;
      gap: 8px 0;
      font-size: 12px;
    }
  }

  .pay-mode {
    width: 120px;
    height: 200px;
    background: #f3f3f3;

    .mode-item {
      width: 120px;
      height: 33px;
      line-height: 32px;
      color: #333333;
      font-size: 12px;
      text-align: center;
      cursor: pointer;

      &.active {
        width: 120px;
        height: 33px;
        color: #fff;
        background-image: linear-gradient(90deg, #ffcbac 0%, #fca387 100%);
        border-radius: 4px 0px 0px 0px;
      }
    }
  }
}

.pay-bg {
  width: 938px;
  height: 108px;
  background: url("@/assets/image/paybg.png");
  display: flex;
  align-items: center;
  padding: 0 2rem;
  gap: 0 2rem;
  position: relative;

  .quxiaoIcon {
    position: absolute;
    top: 45px;
    right: 30px;
    cursor: pointer;
  }

  .icons {
    width: 68px;
    height: 68px;
    background: #d3d3d3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
  }

  .userInfo {
    color: #fff;
    display: flex;
    flex-direction: column;
  }
}

.gobtn {
  width: 200px;
  margin: 50px auto 0px auto;
  background-image: var(--btnbg4);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 20px;
}

.gold-numbers {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 500;
}
</style>
