import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import h5Routes from "./h5";
import webRoutes from "./web";

// function getAppRouter(preview) {
//   return createRouter({
//     history: preview ? createWebHashHistory() : createWebHistory(),
//     scrollBehavior: () => ({
//       y: 0
//     }),
//     routes: AppRouter()
//   });
// }

export const AppRouter = function () {
  return isMobile() ? h5Routes : webRoutes;
};

export const router = createRouter({
  history: false ? createWebHashHistory() : createWebHistory(),
  //兼容ios套壳，页面滚动问题
  scrollBehavior: () => ({
    top: 0, y: 0
  }),
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return { ...savedPosition, behavior: 'smooth', }
  //   } else {
  //     return { top: 0, behavior: 'smooth', }
  //   }
  // },
  routes: AppRouter(),
});

export const getAppRouter = (preview) => {
  return createRouter({
    history: preview ? createWebHashHistory() : createWebHistory(),
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: AppRouter(),
  });
};
export default router;
