<template>
  <el-dialog v-model="invite.visible" destroy-on-close append-to-body :close-on-click-modal="false"
    :top="type == 'pc' ? '17%' : '40%'" class="inviteModel" :zIndex="10011" :width="320" :show-close="false">
    <div class="inviInfo">
      <img :src="info.avatar" onerror="errorAvatarImage(this)">
      <p class="name">{{ info.memberUsername || info.nickname }}</p>
      <p>{{ info.agentLevelName }}</p>
    </div>
    <img src="@/assets/image/invite.png">
    <div class="inviteCode">{{ info.inviteCode }}</div>
    <button class="butns" @click="invite.put()">加入其团队</button>
    <template #footer>
      <div class="close" @click="invite.close()">
        <svg t="1682240810163" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="9189" width="20" height="20">
          <path fill="#3D3D3D"
            d="M601.376 512l191.52-191.52c28.096-28.096 30.976-71.168 6.4-95.744s-67.68-21.696-95.744 6.4l-191.52 191.52-191.52-191.52c-28.096-28.096-71.168-30.976-95.744-6.368s-21.696 67.68 6.4 95.744l191.52 191.52-191.52 191.52c-28.096 28.096-30.976 71.168-6.368 95.744s67.68 21.696 95.744-6.4l191.52-191.52 191.52 191.52c28.096 28.096 71.168 30.976 95.744 6.4s21.696-67.68-6.4-95.744l-191.52-191.52z"
            p-id="9190">
          </path>
        </svg>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, toRefs, onMounted, getCurrentInstance, watch } from 'vue'
import router from "@/router";
import { ElMessage as message } from 'element-plus';
import { useStore } from "vuex";
import { storage, isMobile, getQueryVariable } from "@/utils/index";
import { getToken } from "@/utils/auth";

const props = defineProps({
  type: {
    type: String,
    default: 'pc'
  }
})

const store = useStore();
const currentInstance = getCurrentInstance();
const { $http } = currentInstance.appContext.config.globalProperties;

watch(
  () => store.getters.confirmModel,
  (val) => {
    if (val) invite.get()
  }
)

const emits = defineEmits(['ok'])

const invite = reactive({
  visible: false,
  info: {},
  get() {
    let code = storage.getSession('inviteCodeTpl01')
    $http.get("/member/agent/findInfoByCode", {
      params: { inviteCode: code }
    })
      .then(({ data }) => {
        if (data) {
          console.log(data, '====', router)
          invite.info = data
          invite.visible = true
        } else {
          invite.close()
          message.error("邀请码有误！");
        }
      })
      .catch((error) => {
        invite.visible = false
        store.commit('SETCONFIRMMODEL', false)
        let msg = error.response.data?.message
        message.error(msg || "发生错误！");
      });
  },
  put() {
    if (store.getters.isLogin || router.currentRoute.value.name == "MemberLogin") {
      emits('ok')
    } else {
      if (getToken() && store.getters.userInfo?.isTourists == 2) {
        $http.post("/member/become_agent", {
          invitation: invite.info.inviteCode
        })
          .then(({ data }) => {
            message.success(data || "操作成功！");
            //关闭两个弹窗层
            invite.close()
            store.commit('SETAGENTMODEL', false)
            store.dispatch("refreshInfo");
            router.push({ name: "AgentIndex" });
          })
          .catch((error) => {
            let msg = error.response.data?.message
            message.error(msg || "发生错误！");
          });
      } else {
        if (isMobile()) {
          store.commit("SETLOGINPATH", router.currentRoute.value.name);
          router.push({ path: "/login", query: getQueryVariable() || {} });
        } else {
          store.commit("SETLOGINPATH", "/reload");
          store.commit("SETISLOGIN", true);
        }
      }
    }
  },
  close() {
    invite.visible = false
    store.commit('SETCONFIRMMODEL', false)
    storage.clearOneSession('inviteCode')
  }
})
// console.log(invite.visible, '------------------------')
const { info } = toRefs(invite)
</script>
<style lang="scss">
// .invitePcModel {

//   .el-dialog__body {
//     width: 100%;
//     min-height: 390px !important;
//     border-radius: 24px;
//     position: relative;
//   }
// }

.inviteModel {
  border-radius: 24px !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__footer {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;
    border: 0;

    .close {
      // position: absolute;
      // bottom: -12px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #fff;
      color: #3D3D3D;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .el-dialog__body {
    width: 100%;
    min-height: 390px;
    border-radius: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 0;

    .inviteCode {
      width: 240px;
      height: 45px;
      background: #EFEFEF;
      border-radius: 24px;
      font-size: 16px;
      color: #444444;
      margin: 24px 0;
      text-align: center;
      line-height: 45px;
      letter-spacing: 4px;
      font-weight: 600;
    }

    .inviInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;
      background-image: linear-gradient(180deg, #FFE9DC 0%, #FFF9F4 97%);
      border-radius: 24px;
      padding: 28px 0;
      color: #694627;

      .name {
        font-size: 18px;
        color: #000;
      }

      img {
        width: 60px;
        height: 60px;
      }
    }

    .butns {
      width: 240px;
      height: 45px;
      background-image: var(--btnbg4);
      border-radius: 40px;
      color: #fff;
      font-size: 14px;
      border: 0;
    }

    .modelbg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
    }
  }
}
</style>
